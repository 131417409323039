<template>
  <el-container class="container">
    <el-aside>
      <!-- 导航菜单栏 -->
      <nav-bar></nav-bar>
    </el-aside>
    <el-container>
      <el-header>
        <!-- 头部区域 -->
        <head-bar></head-bar>
      </el-header>
      <el-main>
        <!-- 主内容区域 -->
        <main-content></main-content>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import HeadBar from "./HeadBar";
import NavBar from "./NavBar";
import MainContent from "./MainContent";
export default {
  components: {
    HeadBar,
    NavBar,
    MainContent,
  },
  data() {
    return {
      timer: null, // 定时器
      timerOut: null, // 延时
      nowNotyfyId: 0,
      updateMessageInterval: 60000, // 获取消息时间间隔
      afterReadTimeout: 300000, // 稍后阅读时延
    };
  },
  mounted() {
    this.findOneNotReadMessage();
    this.setInterval();
  },
  methods: {
    setInterval() {
      this.timer = setInterval(() => {
        this.findOneNotReadMessage();
      }, this.updateMessageInterval);
    },
    // 从后端获取消息列表，包括已经显示和未显示的消息
    findOneNotReadMessage() {
      this.$api.auth.notify
        .findOneNotReadMessage({ id: this.recordId })
        .then((res) => {
          if (res.code == 200 && res.data && this.nowNotyfyId != res.data.id) {
            this.clearAfeterRead();
            this.messageShow(res.data);
          }
        });
    },
    // 如果有新消息来，说明旧消息已读
    clearAfeterRead() {
      clearTimeout(this.timerOut);
      this.nowNotyfyId = 0;
    },
    messageShow(data) {
      this.nowNotyfyId = data.id;
      this.$confirm(data.content, data.title, {
        confirmButtonText: "朕知道了",
        cancelButtonText: "稍后再奏",
        type: "warning",
        closeOnClickModal: false,
        closeOnPressEscape: false,
      })
        .then(() => {
          // 消息已读
          this.nowNotyfyId = 0;
          this.markToIsRead(data.id);
        })
        .catch(() => {
          // 稍后阅读
          this.timerOut = setTimeout(() => {
            this.messageShow(data);
          }, this.afterReadTimeout);
        });
    },
    markToIsRead(id) {
      console.log("标记已读");
      this.$api.auth.notify.markToIsRead({ id: id }).then((res) => {
        // 获取下一条
        this.findOneNotReadMessage();
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped lang="less" scoped>
.container {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  // background: rgba(224, 234, 235, 0.1);
}
</style>  