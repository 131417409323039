<template>
  <el-table-column :type="type" :prop="prop" :label="label" :width="width" :min-width="minWidth" :sortable="sortable"
    :show-overflow-tooltip="showOverflowTooltip" :formatter="formatter">
    <filter-scope slot="header" slot-scope="scope" :label="scope.column.label" v-model="childValue"
      :listType="listType" :items="items" :api="api" :params="params" :pinyinGroup="pinyinGroup" @change="change" />
  </el-table-column>
</template>

<script>
import FilterScope from "./FilterScope"
export default {
  components: {
    FilterScope,
  },
  props: {
    value: {
      type: Number,
      default() {
        return -100;
      }
    },
    type: {
      type: String,
      default: 'default'
    },
    label: String,
    prop: String,
    width: {},
    minWidth: {},
    sortable: {
      type: [Boolean, String],
      default: false
    },
    formatter: Function,
    listType: {
      type: String,
      default: 'api',
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    api: String,
    params: Object,
    showOverflowTooltip: {
      type: Boolean,
      default: false
    },
    pinyinGroup: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      visible: false,
      childValue: this.value,
    }
  },
  methods: {
    change() {
      this.$emit("input", this.childValue);
      this.$emit("change", true);
    },
  }
}
</script>
