import axios from '../../../axios'

/* 
 * 医馆模块
 */

const preUrl = "/erp/clinic/";

export const findActiveList= () => {
  return axios({
      url: preUrl + 'findActiveList',
      method: 'get'
  })
}

export const findClinicForSetting= (params) => {
  return axios({
      url: preUrl + 'findClinicForSetting',
      method: 'get',
      params
  })
}

export const findInfoList= () => {
  return axios({ 
      url: preUrl + 'findInfoList',
      method: 'get'
  })
}

export const findInfoById= (params) => {
  return axios({ 
      url: preUrl + 'findInfoById',
      method: 'get',
      params
  })
}

// 保存编辑或新增
export const save= (data) => {
  return axios({
      url: preUrl + 'save',
      method: 'post',
      data
  })
}

export const findErpConfigById= (params) => {
  return axios({ 
      url: preUrl + 'findErpConfigById',
      method: 'get',
      params
  })
}

export const findWeiConfigById= (params) => {
  return axios({ 
      url: preUrl + 'findWeiConfigById',
      method: 'get',
      params
  })
}

export const saveErpConfig= (data) => {
  return axios({
      url: preUrl + 'saveErpConfig',
      method: 'post',
      data
  })
}

export const saveWeiConfig= (data) => {
  return axios({
      url: preUrl + 'saveWeiConfig',
      method: 'post',
      data
  })
}

// 逻辑删除
export const logicalDelete= (params) => {
  return axios({
      url: preUrl + 'logicalDelete',
      method: 'delete',
      params
  })
}

export const imageUpload= (data) => {
  return axios({
      url: preUrl + 'imageUpload',
      method: 'post',
      data
  })
}

export const findHomeImages= (params) => {
  return axios({ 
      url: preUrl + 'findHomeImages',
      method: 'get',
      params
  })
}

export const saveHomeImage= (data) => {
  return axios({
      url: preUrl + 'saveHomeImage',
      method: 'post',
      data
  })
}