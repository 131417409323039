export default {
  method: 'get',
  // 基础url前缀
  // baseUrl: "/api",
  baseUrl: process.env.VUE_APP_ROOT_URL,
  // 请求头信息
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    // "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
  },
  // 参数
  data: {},
  // 设置超时时间
  timeout: 60000,
  // 携带凭证
  withCredentials: false,
  // 返回数据类型
  responseType: 'json'
}