import axios from '../../axios'
/* 
 * 用户模块
 */

const preUrl = "/auth/notify/";

// 获取一条未读消息
export const findOneNotReadMessage = () => {
  return axios({
      url: preUrl + 'findOneNotReadMessage',
      method: 'get'
  })
}

// 标记已读
export const markToIsRead = (params) => {
    return axios({
        url: preUrl + 'markToIsRead',
        method: 'patch',
        params
    })
}

// 分页查询一个用户所有的消息
export const findPage = (data) => {
    return axios({
        url: preUrl + 'findPage',
        method: 'post',
        data
    })
}