import axios from '../../axios'

/* 
 * 菜单管理模块
 */

const preUrl = "/admin/menu/";

// 添加菜单API关联
export const addMenuApi = (data) => {
    return axios({
        url: preUrl + 'addMenuApi',
        method: 'post',
        data
    })
 }
 
// 删除
export const batchDelete = (data) => {
    return axios({
        url: preUrl + 'delete',
        method: 'post',
        data
    })
}

 // 删除菜单API关联
 export const deleteMenuApi = (data) => {
    return axios({
        url: preUrl + 'deleteMenuApi',
        method: 'post',
        data
    })
 }

 // 通过ID查询菜单
 export const findById= (params) => {
     return axios({
         url: preUrl + 'findById',
         method: 'get',
         params
     })
 }

 // 通过ID查询API详情
 export const findDetailsById= (params) => {
     return axios({
         url: preUrl + 'findDetailsById',
         method: 'get',
         params
     })
 }

// 查找导航菜单树
export const findMenuTree = () => {
  return axios({
      url: preUrl + 'findTree',
      method: 'get'
  })
}

 // 保存
 export const save = (data) => {
    return axios({
        url: preUrl + 'save',
        method: 'post',
        data
    })
}