import axios from '../../../axios'

/* 
 * 医馆模块
 */

const preUrl = "/erp/coupon/";

export const findList = () => {
  return axios({
      url: preUrl + 'findList',
      method: 'get'
  }) 
}

export const findItemList = () => {
  return axios({
      url: preUrl + 'findItemList',
      method: 'get'
  }) 
}

export const save = (data) => {
  return axios({
      url: preUrl + 'save',
      method: 'post',
      data
  })
}

export const findById = (params) => {
  return axios({
      url: preUrl + 'findById',
      method: 'get',
      params
  })
}

export const findGetList = (params) => {
  return axios({
      url: preUrl + 'findGetList',
      method: 'get',
      params
  })
}

export const couponUse = (params) => {
  return axios({
      url: preUrl + 'couponUse',
      method: 'get',
      params
  })
}

export const findUserCouponGet = (params) => {
  return axios({
      url: preUrl + 'findUserCouponGet',
      method: 'get',
      params
  })
}

export const findGetPage = (data) => {
  return axios({
      url: preUrl + 'findGetPage',
      method: 'post',
      data
  })
}

export const findCouponGet = (params) => {
  return axios({
      url: preUrl + 'findCouponGet',
      method: 'get',
      params
  })
}

export const findUserCouponGetById = (params) => {
  return axios({
      url: preUrl + 'findUserCouponGetById',
      method: 'get',
      params
  })
}