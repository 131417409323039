import axios from '../../axios'

/* 
 * 用户管理模块
 */

const preUrl = "/admin/user/";

export const findPage = (data) => {
    return axios({
        url: preUrl + 'findPage',
        method: 'post',
        data
    })
}

export const updateStatus= (params) => {
    return axios({
        url: preUrl + 'updateStatus',
        method: 'patch',
        params
    })
}

// 保存用户的部门、医生权限
export const savePerm = (data) => {
   return axios({
       url: preUrl + 'savePerm',
       method: 'post',
       data
   })
}

// 删除
export const batchDelete = (data) => {
    return axios({
        url: preUrl + 'delete',
        method: 'post',
        data
    })
}

// 通过ID查询API详情
export const findDetailsById= (params) => {
    return axios({
        url: preUrl + 'findDetailsById',
        method: 'get',
        params
    })
}
// 保存
export const save = (data) => {
    return axios({
        url: preUrl + 'save',
        method: 'post',
        data
    })
}