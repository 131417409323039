<template>
  <el-tooltip :content="'现在是' + (childValue == 1 ? '启用' : '停用') + '状态'">
    <el-switch class="clear-pm" v-model="childValue" @change="change" active-color="#13ce66" inactive-color="#ff4949"
      :active-value="1" :inactive-value="0" @click.native.stop="1" />
  </el-tooltip>
</template>

<script>
export default {
  props: {
    id: Number,
    useful: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      childValue: this.useful,
    }
  },
  watch: {
    useful(value) {
      this.childValue = value;
    },
  },
  methods: {
    change() {
      this.$emit("change", { id: this.id, useful: this.childValue });
    },
  }
}
</script>