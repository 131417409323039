import axios from '../../../axios'

/* 
 * 计量单位模块
 */

const preUrl = "/erp/medicineTypeProcess/";

export const findDetailsList= () => {
  return axios({
      url: preUrl + 'findDetailsList',
      method: 'get'
  })
}

export const save= (data) => {
  return axios({
      url: preUrl + 'save',
      method: 'post',
      data
  })
}

export const logicalDelete= (params) => {
  return axios({
      url: preUrl + 'logicalDelete',
      method: 'delete',
      params
  })
}