import http from './http';
/* 
 * 打印模块
 */

const protocol = "http://";
const preUrl = ":19001/print/";

// 获取打印机列表
export const getPrinterList = (host) => {
  return http({
    url: protocol + host + preUrl + 'getPrinterList',
    method: 'get'
  })
}

// 挂号打印
export const registeredPrint = (data, host) => {
  return http({
    url: protocol + host + preUrl + 'registeredPrint',
    method: 'post',
    data
  })
}

// 收费打印
export const feePrint = (data, host) => {
  return http({
    url: protocol + host + preUrl + 'feePrint',
    method: 'post',
    data
  })
}

// 煎药单打印
export const decoctionPrint = (data, host) => {
  return http({
    url: protocol + host + preUrl + 'decoctionPrint',
    method: 'post',
    data
  })
}