import axios from '../../../axios'

/* 
 * 会员卡模块
 */

const preUrl = "/erp/vipInfo/";

// 分页查找
export const findPage = (data) => {
    return axios({
        url: preUrl + 'findPage',
        method: 'post',
        data
    })
  }
  
  // Excel导出
  export const excelExport = (data) => {
      return axios({
          url: preUrl + 'excelExport',
          method: 'post',
          data,
          responseType: "blob",
      })
  }
  
  // 保存
  export const save = (data) => {
      return axios({
          url: preUrl + 'save',
          method: 'post',
          data
      })
  }
  
  export const findDetailsById= (params) => {
    return axios({
        url: preUrl + 'findDetailsById',
        method: 'get',
        params
    })
  }
  
  // 挂失
  export const reportLoss= (params) => {
    return axios({
        url: preUrl + 'reportLoss',
        method: 'delete',
        params
    })
  }
  
  export const logicalDelete= (params) => {
    return axios({
        url: preUrl + 'logicalDelete',
        method: 'delete',
        params
    })
  }

  // 会员卡补办
  export const reapply= (params) => {
    return axios({
        url: preUrl + 'reapply',
        method: 'post',
        params
    })
  }

  