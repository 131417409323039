import axios from '../../../axios'

const preUrl = "/erp/memberCommodity/";

export const findPage= (data) => {
  return axios({
      url: preUrl + 'findPage',
      method: 'post',
      data
  })
}

export const save= (data) => {
  return axios({
      url: preUrl + 'save',
      method: 'post',
      data
  })
}

export const findDetailsById= (params) => {
  return axios({
      url: preUrl + 'findDetailsById',
      method: 'get',
      params
  })
}

export const logicalDelete= (params) => {
  return axios({
      url: preUrl + 'logicalDelete',
      method: 'delete',
      params
  })
}

export const updateUseful= (params) => {
  return axios({
      url: preUrl + 'updateUseful',
      method: 'patch',
      params
  })
}

export const conditionQuery= (params) => {
  return axios({
      url: preUrl + 'conditionQuery',
      method: 'get',
      params
  })
}