import axios from 'axios';
import { Message } from 'element-ui';


export default function $http(options) {
  return new Promise((resolve, reject) => {
    const instance = axios.create();
    // response 响应拦截器
    instance.interceptors.response.use(
      response => { // http请求成功
        if (response.data.code != 200) { // 1->数据请求失败
          // 输出错误消息
          if (response.data.msg) {
            Message({
              message: response.data.msg,
              type: 'error'
            })
          }
          return Promise.reject(response.data)
        } else {
          return response.data
        }
      },
      error => { // http请求失败
        let msg = error.msg?error.msg:error;
        Message({
          message: "插件连接失败！" + msg,
          type: 'error'
        })
        return Promise.reject(error) // 返回接口返回的错误信息
      }
    )
    // 请求处理
    instance(options).then(res => {
      resolve(res)
      return false
    }).catch(error => {
      reject(error)
    })
  })
}