import axios from '../../../axios'

const preUrl = "/erp/memberLog/";

export const findPage= (data) => {
  return axios({
      url: preUrl + 'findPage',
      method: 'post',
      data,
  })
}

export const excelExport = (data) => {
    return axios({
        url: preUrl + 'excelExport',
        method: 'post',
        data,
        responseType: "blob",
    })
}
