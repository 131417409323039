// 此文件用于批量引入自定义组件
// 在main.js中调用即可将组件全局引入
import KtButton from './KtButton.vue';
import FilterColumn from '../FilterColumn';
import FilterScope from '../FilterColumn/FilterScope';
import UsefulEdit from '../FilterColumn/UsefulEdit';
import FilterItem from './FilterItem';
import MyQuerySearch from './MyQuerySearch';

import MyInfo from '../MyInfo/MyInfo';
import MyInfoGroup from '../MyInfo/MyInfoGroup';
import MyInfoItem from '../MyInfo/MyInfoItem';

import SumBar from '../DispInfo/SumBar'

const mycom = function(Vue) {
    Vue.component('ktButton', KtButton);
    Vue.component('FilterColumn', FilterColumn);
    Vue.component('FilterScope', FilterScope);
    Vue.component('UsefulEdit', UsefulEdit);
    Vue.component('FilterItem', FilterItem);
    Vue.component('MyQuerySearch', MyQuerySearch);
    
    Vue.component('MyInfo', MyInfo);
    Vue.component('MyInfoGroup', MyInfoGroup);
    Vue.component('MyInfoItem', MyInfoItem);

    Vue.component('SumBar', SumBar);
}
export default mycom