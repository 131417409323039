import axios from '../../../axios'

/* 
 * 医馆模块
 */

const preUrl = "/erp/vipLevel/";

export const findList= () => {
  return axios({
      url: preUrl + 'findList',
      method: 'get'
  })
}

// 保存修改
export const save= (data) => {
  return axios({
      url: preUrl + 'save',
      method: 'post',
      data
  })
}