<!--详情界面-->
<template>
  <el-dialog title="您收到的系统消息" width="80vw" height="auto" top="5vh" :visible.sync="isVisible" :append-to-body="true"
    :modal-append-to-body="true" :close-on-click-modal="false" :before-close="dialogClose" v-loading="loading"
    element-loading-text="拼命加载中">
    <div class="work-hose work-hmargin">
      <div class="of-hidden">
        <div class="box-op-medium">
          日期:
          <el-date-picker @change="findPage(true)" v-model="filters.dateRange" type="daterange" unlink-panels
            :clearable="false" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%"
            size="medium" />
        </div>
        <div class="box-op-mini">
          标题：
          <el-input size="medium" placeholder="" v-model="filters.title" @keyup.enter.native="findPage(true)">
          </el-input>
        </div>

        <div class="box-op-mini">
          内容：
          <el-input size="medium" placeholder="" v-model="filters.content" @keyup.enter.native="findPage(true)">
          </el-input>
        </div>

        <div class="box-op-mini">
          是否已读:
          <el-select size="medium" placeholder="" @change="findPage(true)" v-model="filters.isRead">
            <el-option label="不限" :value="-100" />
            <el-option label="已读" :value="1" />
            <el-option label="未读" :value="0" />
          </el-select>
        </div>

        <div class="box-op-tiny box-top-fill">
          <el-button size="medium" type="primary" :disabled="loading" @click="findPage(true)" icon="el-icon-search">筛选
          </el-button>
        </div>
      </div>

      <div class="of-hidden">
        <div class="of-hidden" style="height: 60vh">
          <div v-show="!pageResult.content || pageResult.content.length == 0" style="margin-top: 20vh;" align="center"
            class="of-hidden">
            暂无记录
          </div>
          <el-card class="box-card" v-for="(item, index) in pageResult.content" :key="index"
            :body-style="{ padding: '10px' }">
            <el-row class="top-title">
              <el-col :span="12">
                <span class="fl-left f-bold" style="
                    font-size: 19px;
                    margin-top: 3px;
                  ">{{ item.title }}</span>
                <el-tag :type="item.isRead == 1 ? 'info' : 'success'" style="padding: 0 10px" class="fl-left mlef-10">
                  <span>{{ item.isRead == 1 ? "已读" : "未读" }}</span>
                </el-tag>
              </el-col>
              <el-col :span="6" align="left">来源：{{ item.source }}</el-col>
              <el-col :span="6">时间：{{ dateTimeFormate(item.startTime) }}</el-col>
            </el-row>
            <div class="text item">
              {{ item.content }}
            </div>
          </el-card>
        </div>

        <!-- 分页器 -->
        <div class="fl-right" style="margin-top: 5px">
          <el-pagination align="center"
            @size-change="(val) => { pageResult.pageNum = 1; pageResult.pageSize = val; findPage(); }"
            @current-change="(val) => { pageResult.pageNum = val; findPage(); }" :current-page="pageResult.pageNum"
            :page-sizes="[10, 30, 50, 100, 150, 200]" :page-size="pageResult.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="pageResult.totalSize">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 对话框底部按钮 -->
    <div slot="footer" class="dialog-footer" style="margin-top: -30px">
      <el-button @click.native="dialogClose()">关闭</el-button>
    </div>
  </el-dialog>
</template>


<script>
export default {
  data() {
    return {
      isVisible: true,
      loading: false,
      filters: this.filterEntity(),
      pageResult: this.pageEntity(),
      detailsData: {},
    };
  },
  mounted() {
    this.pageResult.pageSize = 5;
    this.findPage();
  },
  methods: {
    dialogClose() {
      this.$emit("close");
    },
    filterEntity() {
      return {
        dateRange: [],
        title: undefined,
        content: undefined,
        isRead: -100,
      };
    },
    paramsPackage() {
      const filters = this.filters;
      let params = {};
      if (filters.dateRange[0] && filters.dateRange[1]) {
        params.startDate = this.dateFormate(filters.dateRange[0]);
        params.endDate = this.dateFormate(filters.dateRange[1]);
      }
      if (filters.title) params.title = filters.title;
      if (filters.content) params.content = filters.content;
      if (filters.isRead != -100) params.isRead = filters.isRead;
      return params;
    },
    findPage(val) {
      if (val) this.pageResult.pageNum = 1;
      let pageRequest = {
        pageNum: this.pageResult.pageNum,
        pageSize: this.pageResult.pageSize,
      };
      pageRequest.params = this.paramsPackage();
      this.loading = true;
      this.$api.auth.notify
        .findPage(pageRequest)
        .then((res) => {
          this.pageResult = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.box-card {
  width: 100%;
  margin: 5px;
}

.top-title {
  width: 100%;
  height: 40px;
  /* border-bottom: 1px solid #999999 ; */
}
</style>