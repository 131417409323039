/**
 * 全局常量、方法封装模块
 * 通过原型挂载到Vue属性
 * 通过 this.global 调用
 */
 
 // 客户端设置
 const clientId = 'web'; // 客户端ID
 const clientSecret = '123456';   // 客户端密码
 const localTokenName = 'securityToken' + process.env.VUE_APP_TITLE; // 本地缓存的token变量名
 const localRefreshTokenName = 'securityRefreshToken' + process.env.VUE_APP_TITLE; // 本地缓存的refreshToken变量名
 const localCheckedLogin = 'jstCheckedlogin' + process.env.VUE_APP_TITLE; // 本地缓存记住登录
 const localCheckedInfo = 'jstLoginToken' + process.env.VUE_APP_TITLE; // 本地缓存记住登录信息
 const nowLocalServerVersion = 1;    // 本地插件的最新版本

 export default {
     clientId,
     clientSecret,
     localTokenName,
     localRefreshTokenName,
     nowLocalServerVersion,
     localCheckedLogin,
     localCheckedInfo
 }