import axios from '../../../axios'

/* 
 * 医生模块
 */

const preUrl = "/erp/stockAllot/";

// 分页查找
export const findPage = (data) => {
  return axios({
      url: preUrl + 'findPage',
      method: 'post',
      data
  })
}

// Excel导出
export const excelExport = (data) => {
  return axios({
      url: preUrl + 'excelExport',
      method: 'post',
      data,
      responseType: "blob",
  })
}

// 药品出入库
export const medicineAllot= (data) => {
  return axios({
      url: preUrl + 'medicineAllot',
      method: 'post',
      data
  })
}

// 查询详细
export const findDetailsById = (params) => {
  return axios({
      url: preUrl + 'findDetailsById',
      method: 'get',
      params
  })
}

export const findReceiveList = () => {
  return axios({
      url: preUrl + 'findReceiveList',
      method: 'get'
  })
}

// 接收调拨
export const receiveAllot = (params) => {
  return axios({
      url: preUrl + 'receiveAllot',
      method: 'get',
      params
  })
}

// 撤销或拒收调拨，并恢复库存
export const returnAllot= (params) => {
  return axios({
      url: preUrl + 'returnAllot',
      method: 'delete',
      params
  })
}
