import axios from '../../../axios'

/* 
 * 会员卡模块
 */

const preUrl = "/erp/vipRecord/";

// 分页查找
export const findPage = (data) => {
    return axios({
        url: preUrl + 'findPage',
        method: 'post',
        data
    })
  }
  
  // Excel导出
  export const excelExport = (data) => {
      return axios({
          url: preUrl + 'excelExport',
          method: 'post',
          data,
          responseType: "blob",
      })
  }
  
  // 查询单个会员卡消费记录
  export const findByVip= (params) => {
    return axios({
        url: preUrl + 'findByVip',
        method: 'get',
        params
    })
  }
  
  
  // 查询会员卡记录类型
  export const findTypeList= () => {
    return axios({
        url: preUrl + 'findTypeList',
        method: 'get',
    })
  }
  

  