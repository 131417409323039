import axios from '../../../axios'

const preUrl = "/erp/itemReservation/";

export const reservationUse = (params) => {
    return axios({
        url: preUrl + 'reservationUse',
        method: 'get',
        params
    })
}

export const findPage = (data) => {
    return axios({
        url: preUrl + 'findPage',
        method: 'post',
        data
    })
}

export const findDetailsById = (params) => {
    return axios({
        url: preUrl + 'findDetailsById',
        method: 'get',
        params
    })
}

export const submitRefund = (data) => {
    return axios({
        url: preUrl + 'submitRefund',
        method: 'post',
        data
    })
}