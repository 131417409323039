import axios from '../../../axios'

/* 
 * 初复诊模块
 */

const preUrl = "/erp/patient/";

// 分页查找
export const findPage = (data) => {
    return axios({
        url: preUrl + 'findPage',
        method: 'post',
        data
    })
}

// Excel导出
export const excelExport = (data) => {
    return axios({
        url: preUrl + 'excelExport',
        method: 'post',
        data,
        responseType: "blob",
    })
}

// 获取挂号初复诊列表
export const registeredConditionQuery= (params) => {
    return axios({
        url: preUrl + 'registeredConditionQuery',
        method: 'get',
        params
    })
}

// 通过ID查询病例信息
export const queryInfoById= (params) => {
    return axios({
        url: preUrl + 'queryInfoById',
        method: 'get',
        params
    })
}

// 医生工作站-我的患者
export const doctorPatientPage= (data) => {
  return axios({
      url: preUrl + 'doctorPatientPage',
      method: 'post',
      data
  })
}

// 查看电子病例
export const eCase= (params) => {
  return axios({
      url: preUrl + 'eCase',
      method: 'get',
      params
  })
}


export const mergeTemp= () => {
  return axios({
      url: preUrl + 'mergeTemp',
      method: 'post'
  })
}

export const findSimilarPatient= (data) => {
  return axios({
      url: preUrl + 'findSimilarPatient',
      method: 'post',
      data
  })
}

export const patientMerge= (data) => {
  return axios({
      url: preUrl + 'patientMerge',
      method: 'post',
      data
  })
}