import axios from '../../../axios'

/* 
 * 医生模块
 */

const preUrl = "/erp/medicine/";

// 分页查找
export const findPage = (data) => {
  return axios({
      url: preUrl + 'findPage',
      method: 'post',
      data
  })
}

export const findNotAddPage = (data) => {
  return axios({
      url: preUrl + 'findNotAddPage',
      method: 'post',
      data
  })
}

// Excel导出
export const excelExport = (data) => {
    return axios({
        url: preUrl + 'excelExport',
        method: 'post',
        data,
        responseType: "blob",
    })
}

export const conditionQuery= (params) => {
  return axios({
      url: preUrl + 'conditionQuery',
      method: 'get',
      params
  })
}

// 保存
export const save = (data) => {
    return axios({
        url: preUrl + 'save',
        method: 'post',
        data
    })
}

export const findDetailsById= (params) => {
  return axios({
      url: preUrl + 'findDetailsById',
      method: 'get',
      params
  })
}

export const logicalDelete= (params) => {
  return axios({
      url: preUrl + 'logicalDelete',
      method: 'delete',
      params
  })
}
