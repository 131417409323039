import axios from '../../../axios'

const preUrl = "/erp/card/";


export const save = (data) => {
  return axios({
    url: preUrl + 'save',
    method: 'post',
    data
  })
}

export const updateStatus = (params) => {
  return axios({
    url: preUrl + 'updateStatus',
    method: 'patch',
    params
  })
}

export const findList = (params) => {
  return axios({
    url: preUrl + 'findList',
    method: 'get',
    params
  })
}

export const findDetailsById = (params) => {
  return axios({
    url: preUrl + 'findDetailsById',
    method: 'get',
    params
  })
}

export const findItemList = (params) => {
  return axios({
    url: preUrl + 'findItemList',
    method: 'get',
    params
  })
}

export const createLink = (params) => {
  return axios({
    url: preUrl + 'createLink',
    method: 'get',
    params
  })
}

export const promoteSetting = (data) => {
  return axios({
    url: preUrl + 'promoteSetting',
    method: 'post',
    data
  })
}