import axios from '../../../axios'

/* 
 * 处方标签模块 
 */

const preUrl = "/erp/label/";

export const findActiveList= () => {
  return axios({
      url: preUrl + 'findActiveList',
      method: 'get'
  })
}

export const findDetailsList= () => {
  return axios({
      url: preUrl + 'findDetailsList',
      method: 'get'
  })
}

export const save= (data) => {
  return axios({
      url: preUrl + 'save',
      method: 'post',
      data
  })
}

export const logicalDelete= (params) => {
  return axios({
      url: preUrl + 'logicalDelete',
      method: 'delete',
      params
  })
}