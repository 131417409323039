import axios from '../../../axios'

/* 
 * 会员收费模块
 */

const preUrl = "/erp/vipCharge/";

// 远程搜索会员卡信息
export const conditionSearch= (params) => {
  return axios({
      url: preUrl + 'conditionSearch',
      method: 'get',
      params
  })
}

// 查询会员卡收费信息
export const findChargeById= (params) => {
  return axios({
      url: preUrl + 'findChargeById',
      method: 'get',
      params
  })
}

// 1.充值 2.退费（要密码） 5.积分兑换（要密码） 6.兑换积分退返
export const charge= (data) => {
  return axios({
      url: preUrl + 'charge',
      method: 'post',
      data
  })
}