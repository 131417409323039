import axios from '../../../axios'

/* 
 * 处方模块
 */

const preUrl = "/erp/prescription/";


export const findPage= (data) => {
  return axios({
      url: preUrl + 'findPage',
      method: 'post',
      data
  })
}

export const excelExport = (data) => {
    return axios({
        url: preUrl + 'excelExport',
        method: 'post',
        data,
        responseType: "blob",
    })
}

/*
* 获取处方详情
* 用于医生查看处方详情
*/
export const findReviewById= (params) => {
  return axios({
      url: preUrl + 'findReviewById',
      method: 'get',
      params
  })
}

/*
* 获取处方金额详情
* 用于财务处、收费处查看处方金额
*/
export const findChargeReviewById= (params) => {
  return axios({
      url: preUrl + 'findChargeReviewById',
      method: 'get',
      params
  })
}

/*
* 获取煎药单打印信息
*/
export const findDecoctionPrintById= (params) => {
  return axios({
      url: preUrl + 'findDecoctionPrintById',
      method: 'get',
      params
  })
}

// 保存煎药方式
export const saveDecoctionType= (data) => {
  return axios({
      url: preUrl + 'saveDecoctionType',
      method: 'post',
      data
  })
}

export const getAllotWaitReceiveList= (params) => {
  return axios({
      url: preUrl + 'getAllotWaitReceiveList',
      method: 'get',
      params
  })
}

export const findAllotDetails= (params) => {
  return axios({
      url: preUrl + 'findAllotDetails',
      method: 'get',
      params
  })
}

// 获取发药处方
export const findDispensePage= (data) => {
  return axios({
      url: preUrl + 'findDispensePage',
      method: 'post',
      data
  })
}

export const allotPrescriptionReceive= (data) => {
  return axios({
      url: preUrl + 'allotPrescriptionReceive',
      method: 'post',
      data
  })
}

export const findDispenseDetails= (params) => {
  return axios({
      url: preUrl + 'findDispenseDetails',
      method: 'get',
      params
  })
}

// 处方发药
export const dispensePrescription= (data) => {
  return axios({
      url: preUrl + 'dispensePrescription',
      method: 'post',
      data
  })
}

export const batchDispensePrescription= (data) => {
  return axios({
      url: preUrl + 'batchDispensePrescription',
      method: 'post',
      data
  })
}

// 处方退药
export const returnPrescriptionMedicine= (data) => {
  return axios({
      url: preUrl + 'returnPrescriptionMedicine',
      method: 'post',
      data
  })
}

// 药品销售情况统计
export const medicineSaleStatistics= (data) => {
  return axios({
      url: preUrl + 'medicineSaleStatistics',
      method: 'post',
      data
  })
}

export const medicineSaleStatisticsExport = (data) => {
    return axios({
        url: preUrl + 'medicineSaleStatisticsExport',
        method: 'post',
        data,
        responseType: "blob",
    })
}

// 处方标签编辑
export const savePrescriptionType= (params) => {
  return axios({
      url: preUrl + 'savePrescriptionType',
      method: 'post',
      params
  })
}

// 医生处方贴数统计
export const doctorDoseStatistics= (data) => {
  return axios({
      url: preUrl + 'doctorDoseStatistics',
      method: 'post',
      data
  })
}

export const doctorDoseStatisticsExport = (data) => {
    return axios({
        url: preUrl + 'doctorDoseStatisticsExport',
        method: 'post',
        data,
        responseType: "blob",
    })
}

export const doctorPrescriptionPage= (data) => {
  return axios({
      url: preUrl + 'doctorPrescriptionPage',
      method: 'post',
      data
  })
}

export const printMark= (params) => {
  return axios({
      url: preUrl + 'printMark',
      method: 'patch',
      params
  })
}

