<template>
  <div class="headbar" :style="{ background: themeColor }"
    :class="collapse ? 'position-collapse-left' : 'position-left'">
    <!-- 导航收缩 -->
    <div class="hamburg" @click="onCollapse">
      <hamburger style="height: 20px" :isActive="collapse"></hamburger>
    </div>
    <div class="fl-left of-hidden" style="color: #ffffff; font-size: 28px; font-family: KaiTi">
      济世堂中医门诊部
    </div>

    <!-- 用户信息 -->
    <div class="fl-right of-hidden" style="padding: 0px 30px" v-popover:popover-personal>
      <span class="user-info"><img :src="user.avatar" />{{ user.name }}</span>
      <el-popover ref="popover-personal" placement="bottom-end" trigger="click" :visible-arrow="false">
        <personal-panel :user="user"></personal-panel>
      </el-popover>
    </div>

    <div v-if="loginUserInfo.warehouses && loginUserInfo.warehouses.length > 0" class="fl-right of-hidden"
      style="padding: 0px 30px">
      <el-dropdown size="medium" placement="bottom-start">
        <span class="user-info">
          {{ warehouseNow }}
          <i class="el-icon-arrow-down el-icon--right" style="color: #409eff;"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item, index) in loginUserInfo.warehouses" :key="index">
            <el-radio style="width: 100%" v-model="warehouseSelect" :label="item.warehouseId"
              @change="hospitalChangePost()">
              {{ item.warehouseName }}
            </el-radio>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div v-if="loginUserInfo.doctors && loginUserInfo.doctors.length > 0" class="fl-right of-hidden"
      style="padding: 0px 30px">
      <el-dropdown size="medium" placement="bottom-start">
        <span class="user-info">
          {{ doctorNow }}医生
          <i class="el-icon-arrow-down el-icon--right" style="color: #409eff;"></i>
        </span>
        <el-dropdown-menu slot="dropdown" style="max-height:30vh; overflow-y: scroll;">
          <el-dropdown-item v-for="(item, index) in loginUserInfo.doctors" :key="index">
            <el-radio style="width: 100%" v-model="doctorSelect" :label="item.doctorId" @change="hospitalChangePost()">
              {{
                  item.doctorName
              }}</el-radio>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="fl-right of-hidden" style="padding: 0px 30px">
      <el-dropdown size="medium" placement="bottom-start">
        <span class="user-info">
          {{ hospitalNow }}
          <i class="el-icon-arrow-down el-icon--right" style="color: #409eff;"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item, index) in loginUserInfo.clinics" :key="index">
            <el-radio style="width: 100%" v-model="hospitalSelect" :label="item.id" @change="hospitalChangePost()">{{
                item.name
            }}</el-radio>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Hamburger from "@/components/Hamburger";
import PersonalPanel from "@/components/Core/PersonalPanel";
export default {
  components: {
    Hamburger,
    PersonalPanel,
  },
  data() {
    return {
      user: {},
      activeIndex: "1",
      hospitalSelect: 0,
      doctorSelect: 0,
      warehouseSelect: 0,
    };
  },
  mounted() {
    this.findUserInfo();
  },
  computed: {
    ...mapState({
      themeColor: (state) => state.app.themeColor,
      collapse: (state) => state.app.collapse,
      loginUserInfo: (state) => state.app.loginUserInfo,
    }),
    hospitalNow() {
      let nowId = this.loginUserInfo.loginClinicId;
      let hospitals = this.loginUserInfo.clinics;
      for (let i = 0, len = hospitals.length; i < len; i++) {
        if (hospitals[i].id == nowId) return hospitals[i].name;
      }
      return "";
    },
    doctorNow() {
      let nowId = this.loginUserInfo.loginDoctorId;
      if (!nowId) return "";
      let doctors = this.loginUserInfo.doctors;
      if (!doctors) return "";
      for (let i = 0, len = doctors.length; i < len; i++) {
        if (doctors[i].doctorId == nowId) return doctors[i].doctorName;
      }
      return "";
    },
    warehouseNow() {
      let nowId = this.loginUserInfo.loginWarehouseId;
      if (!nowId) return "药房";
      let warehouses = this.loginUserInfo.warehouses;
      if (!warehouses) return "药房";
      for (let i = 0, len = warehouses.length; i < len; i++) {
        if (warehouses[i].warehouseId == nowId) return warehouses[i].warehouseName;
      }
      return "药房";
    },
  },
  methods: {
    // 折叠导航栏
    onCollapse: function () {
      this.$store.commit("onCollapse");
    },
    findUserInfo() {
      // console.log("获取用户信息");
      this.$api.auth.user.findUserInfo().then((res) => {
        this.user = res.data;
        this.$store.commit("setLoginUserInfo", res.data);
        this.hospitalSelect = res.data.loginClinicId;
        this.doctorSelect = res.data.loginDoctorId;
        this.warehouseSelect = res.data.loginWarehouseId;
        if (!this.user.avatar) {
          this.user.avatar = require("@/assets/image/user.png");
        }
        if (this.user.roles) {
          let roleNames = "";
          for (let i = 0; i < this.user.roles.length; i++) {
            roleNames += this.user.roles[i].name;
            roleNames += " ";
          }
          this.user.roleNames = roleNames;
        }
      });
    },
    hospitalChangePost() {
      // this.loading = true
      let userInfo = {
        username: this.$store.state.app.loginUserInfo.account,
        refresh_token: sessionStorage.getItem(this.global.localRefreshTokenName),
        clinic_id: this.hospitalSelect,
        client_id: this.global.clientId,
        client_secret: this.global.clientSecret,
        grant_type: "refresh_token",
      };

      if (this.doctorSelect) {
        userInfo.doctor_id = this.doctorSelect;
      }

      if (this.warehouseSelect) {
        userInfo.warehouse_id = this.warehouseSelect;
      }

      this.$api.auth.oauth
        .login(userInfo)
        .then((res) => {
          console.log("切换用户医馆");
          this.findUserInfo();
          sessionStorage.setItem("user", userInfo.username); // 保存用户到本地会话
          sessionStorage.setItem(this.global.localTokenName, res.data.tokenHead + res.data.token); // 放置token到到本地会话
          sessionStorage.setItem(this.global.localRefreshTokenName, res.data.refreshToken); // 放置refreshToken到到本地会话
          this.$store.commit("menuRouteLoaded", false); // 要求重新加载导航菜单
          const path = this.$route.path;
          console.log("当前路由：" + path);
          this.$router.push("/home");
          setTimeout(() => {
            this.$router.push(path);
          }, 1);
        })
        .catch((err) => {
          this.errMsg("切换失败，请重试！");
        });
    },
  },
};
</script>

<style scoped lang="less" scoped>
.headbar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1030;
  height: auto;
  line-height: 60px;
  border-color: rgba(180, 190, 190, 0.8);
  border-left-width: 1px;
  border-left-style: solid;
  overflow: hidden;
}

.hamburg {
  padding: 5px 15px 0px 15px;
  margin: 0;
  float: left;
  overflow: hidden;
}

.navbar {
  float: left;
}

.toolbar {
  width: auto;
  overflow: hidden;
  float: right;
}

.lang-item {
  font-size: 16px;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}

.lang-item:hover {
  font-size: 18px;
  background: #f0f5f44d;
}

.user-info {
  font-size: 20px;
  color: #fff;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin: 10px 0px 10px 10px;
    float: right;
  }
}

.badge {
  line-height: 18px;
}

.position-left {
  left: 200px;
}

.position-collapse-left {
  left: 65px;
}
</style>