<template>
  <div class="personal-panel">
    <div class="personal-desc" :style="{ background: this.$store.state.app.themeColor }">
      <div class="avatar-container">
        <img class="avatar" :src="user.avatar" />
      </div>
      <div class="name-role">
        <span class="sender">{{ user.name }} - </span>
        <span v-if="user.isRoot == 1">&nbsp;超级管理员</span>
        <span v-for="(item, index) in user.depts" :key="index">&nbsp;{{ item.name }}</span>
      </div>
      <div class="registe-info">
        <span class="registe-info">
          <li class="fa fa-clock-o"></li>
          {{ this.dateFormat(new Date()) }}
        </span>
      </div>
    </div>
    <!-- <div class="main-operation">
      <span class="main-operation-item" @click="openPersonCenter">
        <el-button size="small" icon="fa fa-male"> 个人中心</el-button>
      </span>
      <span class="main-operation-item" @click="openupdatePasswordDialog">
        <el-button size="small" icon="fa fa-key"> 修改密码</el-button>
      </span>
    </div> -->
    <div class="other-operation">
      <div class="other-operation-item" @click="oldOpen()">
        <li class="el-icon-printer"></li>
        1.0系统入口
      </div>
    </div>
    <div class="other-operation">
      <div class="other-operation-item" @click="notifyOpen()">
        <li class="el-icon-message-solid"></li>
        系统消息
      </div>
    </div>
    <div class="other-operation">
      <div class="other-operation-item" @click="openupdatePasswordDialog">
        <li class="fa fa-key"></li>
        修改密码
      </div>
    </div>
    <div class="other-operation">
      <div class="other-operation-item" @click="openPersonCenter">
        <li class="fa fa-male"></li>
        &nbsp;个人中心
      </div>
    </div>
    <div class="other-operation">
      <div class="other-operation-item" @click="clearCache">
        <li class="fa fa-eraser"></li>
        清除缓存
      </div>
    </div>
    <div class="other-operation">
      <div class="other-operation-item" @click="printSettingOpen()">
        <li class="el-icon-printer"></li>
        打印设置
      </div>
    </div>
    <div class="personal-footer" @click="logout">
      <li class="fa fa-sign-out"></li>
      退出登录
    </div>
    <!--修改密码界面-->
    <el-dialog title="修改密码" width="40%" :visible.sync="updatePwdDialogVisible" :close-on-click-modal="false"
      :modal="false">
      <el-form :model="updatePwdDataForm" label-width="120px" :rules="updatePwdDataFormRules" ref="updatePwdDataForm"
        :size="size" @submit.native.prevent>
        <el-form-item label="原密码" prop="password">
          <el-input v-model="updatePwdDataForm.password" type="password" />
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="updatePwdDataForm.newPassword" type="password" />
        </el-form-item>
        <el-form-item label="确认新密码" prop="comfirmPassword">
          <el-input v-model="updatePwdDataForm.comfirmPassword" type="password" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :size="size" @click.native="updatePwdDialogVisible = false">取消</el-button>
        <el-button :size="size" type="primary" @click.native="updatePassword" :loading="updatePwdLoading">确认</el-button>
      </div>
    </el-dialog>

    <notify-dialog v-if="notifyVisible" @close="notifyVisible = false"></notify-dialog>
    <print-setting v-if="printSettingVisible" @close="printSettingVisible = false"></print-setting>
  </div>
</template>

<script>
import NotifyDialog from "./NotifyDialog"
import PrintSetting from "./PrintSetting"
export default {
  name: "PersonalPanel",
  components: {
    NotifyDialog,
    PrintSetting,
  },
  props: {
    user: {
      type: Object,
      default: {
        avatar: "@/assets/image/user.png",
        role: "超级管理员",
        depts: [],
      },
    },
  },
  data() {
    return {
      size: "small",
      updatePwdDialogVisible: false,
      updatePwdLoading: false,
      updatePwdDataForm: {
        password: "",
        newPassword: "",
        comfirmPassword: "",
      },
      updatePwdDataFormRules: {
        password: this.formRuleInput(),
        newPassword: this.formRuleInput(),
        comfirmPassword: this.formRuleInput(),
      },
      notifyVisible: false,
      printSettingVisible: false,
    };
  },
  methods: {
    // 打开消息中心
    notifyOpen() {
      this.notifyVisible = true;
    },
    printSettingOpen() {
      this.printSettingVisible = true;
    },
    // 打开个人中心
    openPersonCenter: function () {
      alert("待开发");
    },
    // 打开修改密码对话框
    openupdatePasswordDialog: function () {
      this.updatePwdDialogVisible = true;
    },
    // 修改密码
    updatePassword: function () {
      this.$refs.updatePwdDataForm.validate((valid) => {
        if (valid) {
          if (
            this.updatePwdDataForm.newPassword !=
            this.updatePwdDataForm.comfirmPassword
          ) {
            this.$message({
              message: "新密码与确认新密码不一致",
              type: "error",
            });
            return;
          }

          this.updatePwdLoading = true;
          let data = {
            oldPassword: this.updatePwdDataForm.password,
            newPassword: this.updatePwdDataForm.newPassword,
          };
          this.$api.auth.user
            .updatePassword(data)
            .then((res) => {
              this.updatePwdLoading = false;
              this.$message({ message: "操作成功", type: "success" });
              this.$refs["updatePwdDataForm"].resetFields();
              this.logoutApi();
              this.updatePwdDialogVisible = false;
            })
            .catch((err) => {
              this.updatePwdLoading = false;
            });
        }
      });
    },
    // 退出登录
    logout() {
      this.logoutApi();
    },
    oldOpen(){
      window.open('http://jstgyg.cn/old', '_blank');
    },
    // 清除缓存并退出登录
    clearCache() {
      this.$confirm("确认清除缓存并退出登录吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          this.deleteCookie("token"); // 清空Cookie里的token
          this.deleteCookie("refreshToken"); // 清空Cookie里的token
          this.logoutApi();
        })
        .catch(() => { });
    },
    logoutApi() {
      localStorage.setItem(this.global.localCheckedInfo, "");
      sessionStorage.removeItem("user");
      this.$router.push("/login");
    },
    // 清除Cookie
    deleteCookie(name) {
      var myDate = new Date();
      myDate.setTime(-1000); // 设置过期时间
      document.cookie = name + "=''; expires=" + myDate.toGMTString();
    },
    openOnlinePage() {
      // 通过菜单URL跳转至指定路由
      this.$router.push("/sys/online");
    },
    // 时间格式化
    dateFormat(date) {
      return this.dateTimeFormate(date);
    },
    // 打开备份还原界面
    showBackupDialog() {
      this.$refs.backupDialog.setBackupVisible(true);
    },
    // 成功还原之后，重新登录
    afterRestore() {
      this.$refs.backupDialog.setBackupVisible(false);
      sessionStorage.removeItem("user");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.personal-panel {
  font-size: 14px;
  width: 280px;
  text-align: center;
  border-color: rgba(180, 190, 190, 0.2);
  border-width: 1px;
  border-style: solid;
  background: rgba(182, 172, 172, 0.1);
  margin: -14px;
}

.personal-desc {
  padding: 15px;
  color: #fff;
}

.avatar {
  width: 80px;
  height: 80px;
  border-radius: 90px;
}

.name-role {
  font-size: 16px;
  padding: 5px;
}

.personal-relation {
  font-size: 16px;
  padding: 12px;
  margin-right: 1px;
  background: rgba(200, 209, 204, 0.3);
}

.relation-item {
  padding: 12px;
}

.relation-item:hover {
  cursor: pointer;
  color: rgb(19, 138, 156);
}

.main-operation {
  padding: 8px;
  margin-right: 1px;
  /* background: rgba(175, 182, 179, 0.3); */
  border-color: rgba(201, 206, 206, 0.2);
  border-top-width: 1px;
  border-top-style: solid;
}

.main-operation-item {
  margin: 15px;
}

.other-operation {
  padding: 5px;
  margin-right: 1px;
  text-align: left;
  border-color: rgba(180, 190, 190, 0.2);
  border-top-width: 1px;
  border-top-style: solid;
}

.other-operation-item {
  padding: 5px;
}

.other-operation-item:hover {
  cursor: pointer;
  background: #9e94941e;
  color: rgb(19, 138, 156);
}

.personal-footer {
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-color: rgba(180, 190, 190, 0.2);
  border-top-width: 1px;
  border-top-style: solid;
}

.personal-footer:hover {
  cursor: pointer;
  color: rgb(19, 138, 156);
  background: #b1a6a61e;
}
</style>