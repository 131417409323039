import axios from '../../../axios'

const preUrl = "/erp/cardOrder/";


export const findPage = (data) => {
  return axios({
    url: preUrl + 'findPage',
    method: 'post',
    data
  })
}

export const findTagSales = (params) => {
  return axios({
    url: preUrl + 'findTagSales',
    method: 'get',
    params
  })
}

export const cardUse = (data) => {
  return axios({
    url: preUrl + 'cardUse',
    method: 'post',
    data
  })
}

export const querySearch = (params) => {
  return axios({
    url: preUrl + 'querySearch',
    method: 'get',
    params
  })
}

export const findDetailsById = (params) => {
  return axios({
    url: preUrl + 'findDetailsById',
    method: 'get',
    params
  })
}

export const submitRefund = (data) => {
  return axios({
    url: preUrl + 'submitRefund',
    method: 'post',
    data
  })
}

export const findGetUsePage = (data) => {
  return axios({
    url: preUrl + 'findGetUsePage',
    method: 'post',
    data
  })
}

export const reservationNotify = (data) => {
  return axios({
    url: preUrl + 'reservationNotify',
    method: 'post',
    data
  })
}

export const refundNotify = (data) => {
  return axios({
    url: preUrl + 'refundNotify',
    method: 'post',
    data
  })
}

export const submitOrder = (data) => {
  return axios({
    url: preUrl + 'submitOrder',
    method: 'post',
    data
  })
}
