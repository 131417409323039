export default {
    state: {
        perms: [],  // 用户权限标识集合
        userUseful:[
            {value: 0, label: '锁定', color: '#F56C6C'},
            {value: 1, label: '正常', color: '#303133'}
        ]
    },
    getters: {
        // 通过状态值查找普通标签
        getUserUseful: state => (value) => {
            var item = state.userUseful.find(todo => todo.value == value);
            return item?item:{label:''};
        },
    },
    mutations: {
        setPerms(state, perms){  // 用户权限标识集合
            state.perms = perms;
        }
    },
    actions: {
    }
}