import axios from '../../../axios'

/* 
 * 医生模块
 */

const preUrl = "/erp/agreement/";

// 分页查找
export const findPage = (data) => {
  return axios({
      url: preUrl + 'findPage',
      method: 'post',
      data
  })
}
  
export const findDoctorPage = (data) => {
  return axios({
      url: preUrl + 'findDoctorPage',
      method: 'post',
      data
  })
}

export const findDetailsById= (params) => {
  return axios({
      url: preUrl + 'findDetailsById',
      method: 'get',
      params
  })
}

// 保存
export const save = (data) => {
    return axios({
        url: preUrl + 'save',
        method: 'post',
        data
    })
}

export const saveOrder = (params) => {
  return axios({
      url: preUrl + 'saveOrder',
      method: 'patch',
      params
  })
}

export const logicalDelete= (params) => {
  return axios({
      url: preUrl + 'logicalDelete',
      method: 'delete',
      params
  })
}


// 就诊页面查询医生个人协定方
export const findDoctorList= (params) => {
  return axios({
      url: preUrl + 'findDoctorList',
      method: 'get',
      params
  })
}

// 拼音码查询公共协定方
export const findPublicByPinyin= (params) => {
  return axios({
      url: preUrl + 'findPublicByPinyin',
      method: 'get',
      params
  })
}
