import axios from '../../axios'

/* 
 * 机构管理模块
 */

const preUrl = "/admin/dept/";

// 删除
export const dataDelete = (params) => {
    return axios({
        url: preUrl + 'delete',
        method: 'delete',
        params
    })
}

// 通过ID查询菜单
export const findById= (params) => {
    return axios({
        url: preUrl + 'findById',
        method: 'get',
        params
    })
}

// 获取部门菜单关联
export const findDeptMenu= (params) => {
    return axios({
        url: preUrl + 'findDeptMenu',
        method: 'get',
        params
    })
}

// 通过ID查询API详情
export const findDetailsById= (params) => {
    return axios({
        url: preUrl + 'findDetailsById',
        method: 'get',
        params
    })
}

// 查询部门机构树(用于选项)
export const findOperationTree = () => {
    return axios({
        url: preUrl + 'findOperationTree',
        method: 'get'
    })
}

// 查询部门机构树
export const findDeptTree = () => {
    return axios({
        url: preUrl + 'findTree',
        method: 'get'
    })
}

// 保存
export const save = (data) => {
    return axios({
        url: preUrl + 'save',
        method: 'post',
        data
    })
}
// 保存部门菜单关联
export const saveDeptMenu = (data) => {
    return axios({
        url: preUrl + 'saveDeptMenu',
        method: 'post',
        data
    })
}