import axios from '../../../axios'

/* 
 * 收费模块
 */

const preUrl = "/erp/charge/";

// 获取待收费列表
export const chargeList = (params) => {
  return axios({
    url: preUrl + 'chargeList',
    method: 'get',
    params
  })
}

// 获取收费详情
export const findChargeDetails = (params) => {
  return axios({
    url: preUrl + 'findChargeDetails',
    method: 'get',
    params
  })
}

// 根据选择的处方和输入的信息计算收费金额
export const calculateReceiveMoney = (data) => {
  return axios({
    url: preUrl + 'calculateReceiveMoney',
    method: 'post',
    data
  })
}

// 收费
export const charge = (data) => {
  return axios({
    url: preUrl + 'charge',
    method: 'post',
    data
  })
}