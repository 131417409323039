<template>
  <el-popover trigger="hover">
    <div v-if="!pinyinGroup" class="select-box">
      <a class="item" :class="{ 'active': value == -100 }" href="javascript:void(0);" @click="itemCheck(-100)">不限</a>
      <a v-for="item in dataList" :key="item.id" class="item" :class="{ 'active': item.id == value }"
        href="javascript:void(0);" @click="itemCheck(item.id)">{{
          item.name }}</a>
    </div>
    <div v-else class="select-box">
      <a class="item" :class="{ 'active': value == -100 }" href="javascript:void(0);" @click="itemCheck(-100)">不限</a>
      <div v-for="(group, index) in groupList" :key="index" class="group">
        <div class="abbr">{{ group.abbr }}</div>
        <div class="items">
          <a v-for="item in group.items" :key="item.id" class="item" :class="{ 'active': item.id == value }"
            href="javascript:void(0);" @click="itemCheck(item.id)">{{ item.name }}</a>
        </div>
      </div>
    </div>
    <span slot="reference">
      <span>{{ label }}</span>
      <el-button type="text" class="clear-pm" style="color:black">
        &nbsp;<i :class="{ 'color-primary': value != -100 }" class="fa fa-solid fa-filter" style="font-size:20px"></i>
      </el-button>
    </span>
  </el-popover>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default() {
        return -100;
      }
    },
    label: String,
    listType: {
      type: String,
      default: 'api',
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    api: String,
    params: Object,
    pinyinGroup: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      childValue:this.value,
      visible: false,
      dataList: [],
      groupList: [],
    }
  },
  watch: {
    value(value) {
      this.childValue = value;
    },
  },
  mounted() {
    switch (this.listType) {
      case "api": this.findFilterItem(); break;
      case "list": this.dataList = JSON.parse(JSON.stringify(this.items)); break;
      case "isOrNot": this.dataList = [{ id: 1, name: '是' }, { id: 0, name: '否' }]; break
      case "useful": this.dataList = [{ id: 1, name: '启用' }, { id: 0, name: '停用' }]; break
    }
  },
  methods: {
    findFilterItem() {
      this.getObjectValue(this.$api, this.api)(this.params)
        .then((res) => {
          let data = JSON.parse(JSON.stringify(res.data));
          if (this.pinyinGroup) {
            this.listGroupByPinyin(data);
          } else {
            this.dataList = data;
          }
        });
    },
    // 按拼音分组
    listGroupByPinyin(data) {
      let list = [];
      data.forEach(item => {
        let abbr = item.pinyinCode ? item.pinyinCode.slice(0, 1) : "#";
        let group = list.find(a => a.abbr == abbr);
        if (!group) {
          group = {
            abbr: abbr,
            items: [],
          }
          list.push(group);
        }
        group.items.push(item);
      })
      this.groupList = list;
    },
    itemCheck(id) {
      this.$emit("input", id);
      this.$emit("change", true);
      // this.visible = false;
    },
  }
}
</script>

<style lang="less" scoped>
.select-box {
  max-height: 500px;
  overflow: auto;

  .item {
    display: block;
    color: black;
    text-decoration: none;
    line-height: 25px;

    &:hover {
      background-color: bisque;
    }

  }


  >.item {
    padding-left: 10px;
  }

  >.group {
    border-top: 1px solid #aaa;
    display: flex;

    >.abbr {
      width: 20px;
      color: indigo;
    }

    >.items {
      flex-grow: 1;
    }
  }

  .active {
    color: #409EFF;
    font-weight: bold;
  }
}
</style>
