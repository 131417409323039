import axios from '../../../axios'

/* 
 * 机构管理模块
 */

const preUrl = "/erp/prescriptionType/";

export const findTree = () => {
  return axios({
      url: preUrl + 'findTree',
      method: 'get'
  })
}

export const findDetailsById= (params) => {
  return axios({
      url: preUrl + 'findDetailsById',
      method: 'get',
      params
  })
}

export const save = (data) => {
  return axios({
      url: preUrl + 'save',
      method: 'post',
      data
  })
}

export const logicalDelete = (params) => {
    return axios({
        url: preUrl + 'logicalDelete',
        method: 'delete',
        params
    })
}