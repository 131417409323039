import axios from '../../../axios'

const preUrl = "/erp/memberCard/";

export const createByPack = (data) => {
  return axios({
    url: preUrl + 'createByPack',
    method: 'post',
    data
  })
}

export const findPage = (data) => {
  return axios({
    url: preUrl + 'findPage',
    method: 'post',
    data
  })
}

export const excelExport = (data) => {
  return axios({
    url: preUrl + 'excelExport',
    method: 'post',
    data,
    responseType: "blob",
  })
}

export const save = (data) => {
  return axios({
    url: preUrl + 'save',
    method: 'post',
    data
  })
}

export const findDetailsById = (params) => {
  return axios({
    url: preUrl + 'findDetailsById',
    method: 'get',
    params
  })
}

export const refundCard = (data) => {
  return axios({
    url: preUrl + 'refundCard',
    method: 'post',
    data
  })
}

export const tranGiveCalculate = (params) => {
  return axios({
    url: preUrl + 'tranGiveCalculate',
    method: 'get',
    params
  })
}

export const tranGive = (params) => {
  return axios({
    url: preUrl + 'tranGive',
    method: 'post',
    params
  })
}

