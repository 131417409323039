import axios from '../../../axios'

/* 
 * 药品名称模块
 */

const preUrl = "/erp/medicineName/";

  // 拼音码查询协定方药品
  export const findCommonByPinyin= (params) => {
    return axios({
        url: preUrl + 'findCommonByPinyin',
        method: 'get',
        params
    })
  }
  