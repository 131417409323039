import axios from '../../../axios'

/* 
 * 医馆模块
 */

const preUrl = "/erp/reservation/";

export const findPage = (data) => {
  return axios({
      url: preUrl + 'findPage',
      method: 'post',
      data
  })
}

// Excel导出
export const excelExport = (data) => {
    return axios({
        url: preUrl + 'excelExport',
        method: 'post',
        data,
        responseType: "blob",
    })
}

// 查询详细
export const findDetailsById = (params) => {
    return axios({
        url: preUrl + 'findDetailsById',
        method: 'get',
        params
    })
}


export const findView = () => {
  return axios({
      url: preUrl + 'findView',
      method: 'get'
  })
}

export const findChartsData = () => {
  return axios({
      url: preUrl + 'findChartsData',
      method: 'get'
  })
}

export const reservationStatistics = (params) => {
  return axios({
      url: preUrl + 'reservationStatistics',
      method: 'get',
      params
  })
}

// Excel导出
export const reservationStatisticsExport = (params) => {
    return axios({
        url: preUrl + 'reservationStatisticsExport',
        method: 'post',
        params,
        responseType: "blob",
    })
}