<template>
  <div class="my-info-group" :class="{ 'disabled': disabled }">
    <div class="ribbon" v-if="ribbon">{{ ribbon }}</div>
    <div class="header">
      <div class="title">{{ title }}</div>
      <div class="option">
        <slot name="option"></slot>
      </div>
    </div>
    <div class="content">
      <div class="list">
        <slot></slot>
      </div>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: {
    title: {
      type: String,
      default: "标题",
    },
    ribbon: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="less" scoped>
.my-info-group {
  border-radius: 5px;
  border: 1px solid #eee;
  position: relative;

  .ribbon {
    width: 80px;
    height: 25px;
    border: 2px solid #dd0d1f;
    position: absolute;
    right: 0px;
    top: 25px;
    z-index: 999;
    transform: rotateZ(45deg);
    text-align: center;
    color: #dd0d1f;
    // font-size: 12px;
    font-weight: bold;
  }

  >.header {
    background-color: #F4F7FE;
    padding: 5px;
    border-bottom: 1px solid #eee;
    display: flex;

    >.title {
      min-width: 100px;
      font-weight: bold;
      color: #333;
    }

    >.option {
      flex-grow: 1;
    }
  }

  >.content {
    padding: 5px;

    >.list {
      display: flex;
      flex-flow: row wrap;
    }
  }
}

.disabled {
  color: #999;
}
</style>