/* 
 * 接口统一集成模块
 */

 // 系统接口
import * as admin from './modules/admin'
import * as auth from './modules/auth'
import * as erp from './modules/erp'
import * as localServer from './modules/localServer'

// 元数据接口


// 默认全部导出
export default {
    admin,
    auth,
    erp,
    localServer,
}