import axios from '../../../axios'

/* 
 * 财务目标模块 
 */

const preUrl = "/erp/accountantGoal/";

export const findBriefList= (params) => {
  return axios({
      url: preUrl + 'findBriefList',
      method: 'get',
      params
  })
}

// 保存编辑或新增
export const save= (data) => {
  return axios({
      url: preUrl + 'save',
      method: 'post',
      data
  })
}

export const findDetails= (params) => {
  return axios({
      url: preUrl + 'findDetails',
      method: 'get',
      params
  })
}