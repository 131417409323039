import axios from '../../../axios'

const preUrl = "/erp/memberOrder/";

export const findPage= (data) => {
  return axios({
      url: preUrl + 'findPage',
      method: 'post',
      data
  })
}

export const excelExport = (data) => {
    return axios({
        url: preUrl + 'excelExport',
        method: 'post',
        data,
        responseType: "blob",
    })
}

export const findAllPage= (data) => {
  return axios({
      url: preUrl + 'findAllPage',
      method: 'post',
      data
  })
}

export const excelAllExport = (data) => {
    return axios({
        url: preUrl + 'excelAllExport',
        method: 'post',
        data,
        responseType: "blob",
    })
}

export const findDetails= (params) => {
  return axios({
      url: preUrl + 'findDetails',
      method: 'get',
      params
  })
}

export const payOrder= (data) => {
  return axios({
      url: preUrl + 'payOrder',
      method: 'post',
      data
  })
}

export const directPayOrder= (data) => {
  return axios({
      url: preUrl + 'directPayOrder',
      method: 'post',
      data
  })
}

export const cancelOrder= (params) => {
  return axios({
      url: preUrl + 'cancelOrder',
      method: 'delete',
      params
  })
}

export const payDebtMoney= (data) => {
  return axios({
      url: preUrl + 'payDebtMoney',
      method: 'post',
      data
  })
}