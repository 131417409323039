import axios from '../../../axios'

const preUrl = "/erp/homePage/";

export const findHome = () => {
  return axios({
      url: preUrl + 'findHome',
      method: 'get'
  })
}

export const findList = () => {
  return axios({
      url: preUrl + 'findList',
      method: 'get'
  })
}

export const findDetails = (params) => {
  return axios({
      url: preUrl + 'findDetails',
      method: 'get',
      params
  })
}

export const save = (data) => {
  return axios({
      url: preUrl + 'save',
      method: 'post',
      data
  })
}