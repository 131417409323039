<template>
  <el-submenu v-if="menu.children && menu.children.length >= 1" :index="'' + menu.id">
    <template slot="title">
      <i :class="menu.icon"></i>
      <span slot="title">{{ menu.name }}
        <el-badge v-show="menu.badge" :value="menu.badge" class="item">
        </el-badge>
      </span>
    </template>
    <MenuTree v-for="item in menu.children" :key="item.id" :menu="item"></MenuTree>
  </el-submenu>
  <el-menu-item v-else :index="'' + menu.id" @click="handleRoute(menu)">
    <i :class="menu.icon"></i>
    <span slot="title">{{ menu.name }}
      <el-badge v-show="menu.badge" :value="menu.badge" class="item">
      </el-badge>
    </span>
  </el-menu-item>
</template>

<script>
import { getIFrameUrl, getIFramePath } from '@/utils/iframe'
export default {
  name: 'MenuTree',
  props: {
    menu: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleRoute(menu) {
      // 如果是嵌套页面，转换成iframe的path
      let path = getIFramePath(menu.url)
      if (!path) {
        path = menu.url
      }
      path = "/" + path;
      // 防止重复跳转
      if (path != this.$route.path) {
        // 通过菜单URL跳转至指定路由
        this.$router.push(path);
      }
    }
  }
}
</script>

<style scoped lang="less" scoped>

</style>