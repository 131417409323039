export default {
    state: {
        isFreeList: [
            {
                value: 0,
                label: '无免号'
            },
            {
                value: 1,
                label: '男性免号'
            },{
                value: 2,
                label: '女性免号'
            },{
                value: 3,
                label: '全免号'
            },
        ]
    },
    getters: {
        // 通过状态值查找普通标签
        getIsFreeById: state => (value) => {
            var item = state.isFreeList.find(todo => todo.value == value);
            return item ? item.label : '';
        },
    },
    mutations: {},
    actions: {}
}