import axios from '../../../axios'

/* 
 * 医生模块
 */

const preUrl = "/erp/stock/";

// 查询一种药品的库存记录
export const findByWarehouseMedicine= (params) => {
  return axios({
      url: preUrl + 'findByWarehouseMedicine',
      method: 'get',
      params
  })
}
