import axios from '../../axios'

/* 
 * API管理模块
 */

const preUrl = "/admin/api/";

// 删除
export const batchDelete = (data) => {
    return axios({
        url: preUrl + 'delete',
        method: 'post',
        data
    })
}

// 通过ID查询API
export const findById= (params) => {
    return axios({
        url: preUrl + 'findById',
        method: 'get',
        params
    })
}

// 查询菜单对应API
export const findByMenu= (params) => {
    return axios({
        url: preUrl + 'findByMenu',
        method: 'get',
        params
    })
}

// 使用URL或备注模糊查询API
export const findByUrlOrRemark= (params) => {
    return axios({
        url: preUrl + 'findByUrlOrRemark',
        method: 'get',
        params
    })
}

// 通过ID查询API详情
export const findDetailsById= (params) => {
    return axios({
        url: preUrl + 'findDetailsById',
        method: 'get',
        params
    })
}

// 分页查询
export const findPage = (data) => {
    return axios({
        url: preUrl + 'findPage',
        method: 'post',
        data
    })
}

// 保存
export const save = (data) => {
    return axios({
        url: preUrl + 'save',
        method: 'post',
        data
    })
}