import axios from '../../../axios'

/* 
 * 医生模块
 */

const preUrl = "/erp/stockRecord/";

// 分页查找
export const findPage = (data) => {
  return axios({
      url: preUrl + 'findPage',
      method: 'post',
      data
  })
}

// Excel导出
export const excelExport = (data) => {
  return axios({
      url: preUrl + 'excelExport',
      method: 'post',
      data,
      responseType: "blob",
  })
}

export const findAllPage = (data) => {
  return axios({
      url: preUrl + 'findAllPage',
      method: 'post',
      data
  })
}

export const excelAllExport = (data) => {
  return axios({
      url: preUrl + 'excelAllExport',
      method: 'post',
      data,
      responseType: "blob",
  })
}

// 进销存
export const inAndOutStatistics = (data) => {
  return axios({
      url: preUrl + 'inAndOutStatistics',
      method: 'post',
      data
  })
}

export const inAndOutStatisticsExport = (data) => {
  return axios({
      url: preUrl + 'inAndOutStatisticsExport',
      method: 'post',
      data,
      responseType: "blob",
  })
}