/**
 * 实体类全局方法
 */

 exports.install = function (Vue, options) {
  
    // 实体类
    Vue.prototype.xxxEntity = function () {
      return {
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
        id: undefined,
      }
    }

    
  
    // 分页查找实体类
    Vue.prototype.pageEntity = function (num) {
      return {
        content: [],
        sumData: {},
        pageNum: 1,
        pageSize: num?num:10,
        totalPages: 0,
        totalSize: 0,
      }
    }
  }