import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import api from './http'  // http请求封装
import global from '@/utils/global' // 全局变量
import ElementUI from 'element-ui'  // 引入Element组件
import 'element-ui/lib/theme-chalk/index.css' // 引入Element主题
import './assets/style/theme/index.css' // 引入自定义Element主题
import 'font-awesome/css/font-awesome.min.css'  // 引入字体图标库

Vue.config.productionTip = false

// 模块注册
Vue.use(ElementUI)  // 注册使用Element
Vue.use(api)  // 注册使用API模块
Vue.prototype.global = global // 挂载全局配置模块

//  自定义全局方法
import globalFunctions from './utils/global-functions'  // 自定义全局方法
import globalEntity from './utils/global-entity'  // 自定义全局实体类
Vue.use(globalFunctions)
Vue.use(globalEntity)

// 引入打印
// import Print from 'vue-print-nb'
// Vue.use(Print)
import Print from 'print-js'
// 解决Printjs字体大小问题：P:\jishitang\jishitang-cloud-web\node_modules\print-js\dist\print.js
// 把elementStyle += 'max-width: ' + params.maxWidth + 'px !important; font-size: ' + params.font_size + ' !important;'; 替换成：
//  elementStyle += 'max-width: ' + params.maxWidth + 'px !important;'; //解决print.js 不能修改font_size属性问题

// 引入Echart图表
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

import VueCropper from 'vue-cropper' // 图片裁剪工具
Vue.use(VueCropper)


// 自定义全局样式
import './assets/css/common.css' // 自定义样式
import './assets/css/jishitang.css' // 自定义样式

// 全局引入自定义组件
import mycom from '@/components/MyCom/mycom'
Vue.use(mycom)

// 引入fontsome字体图标库
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText }
 from '@fortawesome/vue-fontawesome'
library.add(fas, far, fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
