import axios from '../../../axios'

const preUrl = "/erp/memberConsume/";

export const findPage= (data) => {
  return axios({
      url: preUrl + 'findPage',
      method: 'post',
      data
  })
}

export const excelExport = (data) => {
    return axios({
        url: preUrl + 'excelExport',
        method: 'post',
        data,
        responseType: "blob",
    })
}

export const itemMoneyCalculate= (data) => {
  return axios({
      url: preUrl + 'itemMoneyCalculate',
      method: 'post',
      data
  })
}

export const itemPay= (data) => {
  return axios({
      url: preUrl + 'itemPay',
      method: 'post',
      data
  })
}

export const recordCheck= (params) => {
  return axios({
      url: preUrl + 'recordCheck',
      method: 'post',
      params
  })
}

export const recordCancel= (params) => {
  return axios({
      url: preUrl + 'recordCancel',
      method: 'post',
      params
  })
}

export const findDetails= (params) => {
  return axios({
      url: preUrl + 'findDetails',
      method: 'get',
      params
  })
}

export const findList= (params) => {
  return axios({
      url: preUrl + 'findList',
      method: 'get',
      params
  })
}

export const doctorSaleStatistics= (data) => {
  return axios({
      url: preUrl + 'doctorSaleStatistics',
      method: 'post',
      data
  })
}