import axios from '../../../axios'

/* 
 * 排班模块
 */

const preUrl = "/erp/schedule/";

export const getList = (params) => {
  return axios({
      url: preUrl + 'getList',
      method: 'get',
      params
  })
}

export const findEditData = (params) => {
  return axios({
      url: preUrl + 'findEditData',
      method: 'get',
      params
  })
}

export const putSchedule = (data) => {
  return axios({
      url: preUrl + 'putSchedule',
      method: 'post',
      data
  })
}

export const save = (data) => {
  return axios({
      url: preUrl + 'save',
      method: 'post',
      data
  })
}

export const findTemplate = () => {
  return axios({
      url: preUrl + 'findTemplate',
      method: 'get'
  })
}

export const saveTemplate = (data) => {
  return axios({
      url: preUrl + 'saveTemplate',
      method: 'post',
      data
  })
}

export const deleteTemplate = (params) => {
  return axios({
      url: preUrl + 'deleteTemplate',
      method: 'delete',
      params
  })
}