<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    // setWaterMark('', '测试版系统');
  },
};
</script>

<style lang="less">
* {
  font-size: 17px;
}

// 加大选项内字体大小
.el-form-item__label,
.el-radio__label,
.el-alert__title {
  font-size: 17px !important;
}

// 加大输入框内字体大小
.el-input__inner {
  font-size: 17px !important;
  text-align: left !important;
}

// 远程搜索选项颜色加深
.el-autocomplete-suggestion li.highlighted,
.el-autocomplete-suggestion li:hover {
  background-color: #67C23A !important;
}

// 表格排序图表加大
.caret-wrapper{
  transform: scaleX(1.5) scaleY(2);
}

// .el-table--border .el-table__cell:first-child .cell {
//     padding: 0 !important;
//     text-align: center;
// }

// 去除表格标题的边距
.el-table th.el-table__cell>.cell{
  padding: 0 !important;
  text-align: center;
}

// 去除指定了clear样式名称的单元格的所有边距
.el-table td.clear>.cell{
  padding: 0 !important;
}

.el-table td.clear-sum>.cell{
  padding: 5px 0 !important;
  // font-weight: bold;
}

/* 对已就诊的进行颜色加深 */
.el-table .complish-clinic {
  background: #eeeeee !important;
}

// 全屏对话框去除边距
.is-fullscreen .el-dialog__body{
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0 !important;
}

#app {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 12px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 12px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: skyblue;
  background-image: -webkit-linear-gradient(45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent);
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: cornsilk;
  border-radius: 10px;
}
</style>
