import axios from '../../../axios'

const preUrl = "/erp/item/";

export const findList = (params) => {
    return axios({
        url: preUrl + 'findList',
        method: 'get',
        params
    })
}

export const findDetailsById = (params) => {
    return axios({
        url: preUrl + 'findDetailsById',
        method: 'get',
        params
    })
}

export const findById = (params) => {
    return axios({
        url: preUrl + 'findById',
        method: 'get',
        params
    })
}

export const save = (data) => {
    return axios({
        url: preUrl + 'save',
        method: 'post',
        data
    })
}

export const findItemList = (params) => {
    return axios({
        url: preUrl + 'findItemList',
        method: 'get',
        params
    })
}