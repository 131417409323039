export default {
    state: {
        appName: "济 世 堂 中 医 门 诊 部",  // 应用名称
        themeColor: "#30D4EE", // 主题颜色
        oldThemeColor: "#30D4EE", // 上一次主题颜色
        collapse:false, // 导航栏收缩状态
        menuRouteLoaded:false, // 菜单和路由是否已经加载
        loginUserInfo:{
            id:0,
            account: "",
            name:"",
            avatar:"",
            depts:"",   // 角色
            isRoot:0,
            lastLoginTime:undefined,
            loginClinicId:0,
            loginDoctorId:0,
            clinics:[],
            doctors:[],
        },
        printParamPrefix: "print-setting-",
        printSettingList: [
            {
              name: "registeredPrint",
              title: "挂号打印",
              exist: false,
              minVersion: 1,  // 最小可用版本
              printerList: [],
              setting: {},
              default: {
                host: "localhost",
                marginLeft: 24,
                marginTop: 17,
                printerName: "",
                autoPrint: false
              },
              testData: {
                time: "2022-06-14 17:01:13",
                orderNumber: "66",
                name: "曾猛徕",
                age: "25",
                sex: "男",
                symptom: "头晕",
                doctor: "李四",
                money: "200",
                phoneNumber: "12345678901",
                source: "路过",
                office: "中医内科",
                address: "江西省 赣州市 章贡区 蟠龙镇 赣南师范大学 北苑五栋宿舍楼 421室",
                registeredType: "专家号",
              },
              hostInfo: {}
            },
            {
              name: "feePrint",
              title: "收费打印",
              exist: false,
              minVersion: 1,  // 最小可用版本
              printerList: [],
              setting: {},
              default: {
                host: "localhost",
                marginLeft: 36,
                marginTop: 19,
                printerName: "",
                autoPrint: false
              },
              testData: {
                orderNumber: "101",
                date: "2022-06-13",
                name: "曾猛徕",
                medicineMoney: "126.00",
                diagnosisMoney: "136.00",
                otherMoney: "8.88",
                chineseMoney: "壹贰叁肆伍陆柒",
                money: "12345.67",
                doctorName: "李四",
                cashier: "王五",
              },
              hostInfo: {}
            },
            {
              name: "decoctionPrint",
              title: "煎药单打印",
              exist: false,
              minVersion: 1,  // 最小可用版本
              printerList: [],
              setting: {},
              default: {
                host: "localhost",
                marginLeft: 7,
                marginTop: 3,
                printerName: "",
                autoPrint: false
              },
              testData: { 
                name: "曾猛徕",
                phoneNumber: "12345678901",
                dose: "1",
                multiply: "2",
                bags: "2",
                decoctionTypeName: "砂锅熬",
                address: "江西省 赣州市 章贡区 蟠龙镇 赣南师范大学 北苑五栋宿舍楼 421室",
                doctorName: "李四",
                doctorAdvice: "上药水煎浓缩，加桑椹清膏1份，麦芽糖收膏，每日2次，一次一袋，一袋20g，早晚调温开水服用.",
                consultPhone: "1234567",
                date: "2022-06-13",
              },
              hostInfo: {}
            },
          ]
    },
    getters: {
        collapse(state){// 对应着上面state
            return state.collapse
        },
        getLoginUserInfo(state){// 对应着上面state
            return state.userInfo
        }
    },
    mutations: {
        onCollapse(state){  // 改变收缩状态
            state.collapse = !state.collapse
        },
        setThemeColor(state, themeColor){  // 改变主题颜色
            state.oldThemeColor = state.themeColor
            state.themeColor = themeColor
        },
        menuRouteLoaded(state, menuRouteLoaded){  // 改变菜单和路由的加载状态
            state.menuRouteLoaded = menuRouteLoaded;
        },
        setLoginUserInfo(state, loginUserInfo){  // 保存登录用户信息
            state.loginUserInfo = JSON.parse(JSON.stringify(loginUserInfo));
        },
    },
    actions: {
    }
}