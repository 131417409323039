import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 引入子模块
import app from './modules/app'
import erp from './modules/erp'
import tab from './modules/tab'
import iframe from './modules/iframe'
import user from './modules/user'
import menu from './modules/menu'
import wei from './modules/wei'

export default new Vuex.Store({
  modules: {
      app: app,
      erp: erp,
      tab: tab,
      iframe: iframe,
      user: user,
      menu: menu,
      wei:wei,
  }
})
