<template>
  <div class="login-main" align="center">
    <el-form :model="loginForm" :rules="fieldRules" ref="loginForm" label-position="left" label-width="0px"
      class="demo-ruleForm login-container" @submit.native.prevent>
      <div style="padding:5px;">
        <h2 class="clear-pm fl-left" style="padding-left: 10px; width:200px; color: #505458;">济世堂中医门诊部</h2>
        <el-button class="clear-pm fl-right mtop-5" type="text" @click="softDownload()">
          <i class="fa fa-windows" aria-hidden="true"></i> 下载客户端软件
        </el-button>
      </div>
      <div style="padding: 35px;">
        <div class="avatar-container">
          <img class="avatar" :src="loginForm.avatar" />
        </div>
        <el-form-item prop="username">
          <el-input ref='account' @keyup.enter.native="$refs.password.focus()" type="text" v-model="loginForm.username"
            placeholder="账号" @blur="getPreInfo()" />
        </el-form-item>
        <el-form-item prop="password">
          <el-input ref='password' @keyup.enter.native="login()" type="password" v-model="loginForm.password"
            placeholder="密码" />
        </el-form-item>
        <el-form-item prop="clinicId">
          <el-select ref='clinicId' v-model="loginForm.clinicId" style="width:100%" placeholder="请选择医馆">
            <el-option v-for="(item, index) in clinicList" :key="index" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item v-show="loginForm.isNeedCaptcha" :error="loginForm.captchaReason">
          <el-col :span="12">
            <el-form-item prop="captcha">
              <el-input ref='captcha' type="test" v-model="loginForm.captcha" placeholder="验证码, 单击图片刷新"
                style="width: 100%">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="1">&nbsp;</el-col>
          <el-col :span="11">
            <el-form-item>
              <img style="width: 120px; height: 40px" class="pointer" :src="loginForm.src" @click="refreshCaptcha" />
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-col :span="12">
            <el-checkbox v-model="checked">7天免登录</el-checkbox>
          </el-col>
          <el-col :span="11">
            <el-popover placement="top-start" title="密码忘了怎么办？" width="200" trigger="hover"
              content="此功能暂未开发，请联系管理员重置密码。">
              <el-button type="text" slot="reference">忘记密码？</el-button>
            </el-popover>
          </el-col>
        </el-form-item>
        <el-form-item style="width: 100%">
          <el-button type="danger" style="width: 48%" @click.native.prevent="reset">重 置</el-button>
          <el-button type="primary" style="width: 48%" @click.native.prevent="loginCheck()" :loading="loading">登 录
          </el-button>
        </el-form-item>
          <div>
            <el-link type="primary" @click="oldOpen()">
              <span style="font-size:30x;">>>>回到1.0系统</span>
            </el-link>
          </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ThemePicker from "@/components/ThemePicker";

export default {
  name: "Login",
  components: {
    ThemePicker,
  },
  data() {
    return {
      loading: false,
      downloadUrl: "https://wei-1258896086.cos.ap-shanghai.myqcloud.com/download/%E6%B5%8E%E4%B8%96%E5%A0%82%E6%94%B6%E8%B4%B9%E7%B3%BB%E7%BB%9F2.0.exe",
      loginForm: {
        username: "",
        password: "",
        clinicId: undefined,
        captcha: "",
        captchaSerialNo: "",
        captchaReason: "",
        isNeedCaptcha: false,
        src: "",
        avatar: this.avatar()
      },
      fieldRules: {
        username: this.formRuleInput(),
        password: this.formRuleInput(),
        clinicId: this.formRuleSelect(),
      },
      clinicList: [],
      checked: false,
    };
  },
  mounted() {
    let checked = localStorage.getItem(this.global.localCheckedLogin);
    console.log(checked);
    if (this.notEmptyN(checked) && "true" == checked) {
      this.checked = true;
      let obj = localStorage.getItem(this.global.localCheckedInfo);
      console.log("免登录：")
      console.log(obj)
      if (obj && typeof (obj) == 'string') {
        let data = JSON.parse(obj);
        if (data && data.account && data.refreshToken && data.clinicId) {
          this.tokenLogin(data);
        } else {
          localStorage.setItem(this.global.localCheckedInfo, "");
        }
      }
    } else {
      this.checked = false;
    }
  },
  methods: {
    softDownload() {
      window.open(this.downloadUrl, "_blank");
    },
    avatar() {
      return require("@/assets/image/user.png");
    },
    loginCheck() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.login();
        }
      });
    },
    oldOpen(){
      window.open('http://jstgyg.cn/old', '_blank');
    },
    login() {
      // this.loading = true
      let userInfo = {
        username: this.loginForm.username,
        password: this.loginForm.password,
        clinic_id: this.loginForm.clinicId,
        // doctor_id: 1,
        client_id: this.global.clientId,
        client_secret: this.global.clientSecret,
        grant_type: "password",
      };

      if (this.loginForm.isNeedCaptcha) {
        userInfo.captcha = this.loginForm.captcha;
        userInfo.captcha_serial = this.loginForm.captchaSerialNo;
      }

      this.$api.auth.oauth.login(userInfo).then((res) => {
        sessionStorage.setItem("user", userInfo.username); // 保存用户到本地会话
        if (this.checked) {
          localStorage.setItem(this.global.localCheckedLogin, "true");
          localStorage.setItem(this.global.localCheckedInfo, JSON.stringify({
            account: this.loginForm.username,
            refreshToken: res.data.refreshToken,
            clinicId: this.loginForm.clinicId
          }));
        } else {
          localStorage.setItem(this.global.localCheckedLogin, "false");
          localStorage.setItem(this.global.localCheckedInfo, "");
        }
        sessionStorage.setItem(this.global.localTokenName, res.data.tokenHead + res.data.token); // 放置token到到本地会话
        sessionStorage.setItem(this.global.localRefreshTokenName, res.data.refreshToken); // 放置refreshToken到到本地会话
        this.$store.commit("menuRouteLoaded", false); // 要求重新加载导航菜单
        this.$router.push("/"); // 登录成功，跳转到主页
      }).catch(err => {
        console.log(err);
        this.getPreInfo();
      });
    },
    // 记住密码的情况下用refreshToken登录
    tokenLogin(data) {
      console.log("刷新令牌登录")
      let userInfo = {
        username: data.account,
        refresh_token: data.refreshToken,
        clinic_id: data.clinicId,
        client_id: this.global.clientId,
        client_secret: this.global.clientSecret,
        grant_type: "refresh_token",
      };
      this.$api.auth.oauth
        .login(userInfo)
        .then((res) => {
          sessionStorage.setItem("user", userInfo.username); // 保存用户到本地会话
          localStorage.setItem(this.global.localCheckedLogin, this.checked);
          sessionStorage.setItem(this.global.localTokenName, res.data.tokenHead + res.data.token); // 放置token到到本地会话
          sessionStorage.setItem(this.global.localRefreshTokenName, res.data.refreshToken); // 放置refreshToken到到本地会话
          this.$store.commit("menuRouteLoaded", false); // 要求重新加载导航菜单
          this.$router.push("/"); // 登录成功，跳转到主页
        });
    },
    refreshCaptcha: function () {
      this.$api.auth.oauth.captcha().then((res) => {
        console.log(res.data);
        this.loginForm.src = res.data.image;
        this.loginForm.captchaSerialNo = res.data.serial;

        // 测试用
        if (res.data.code) {
          this.loginForm.captcha = res.data.code;
        } else {
          this.loginForm.captcha = "";
        }
      });
    },
    getPreInfo() {
      console.log(this.loginForm.username)
      if (!this.loginForm.username || this.loginForm.username == '') {
        this.setInit();
        return;
      }
      this.$api.auth.oauth.getPreInfo({ account: this.loginForm.username }).then((res) => {
        console.log(res.data);
        if (res.data) {
          if (res.data.avatar) {
            this.loginForm.avatar = res.data.avatar;
          } else {
            this.loginForm.avatar = this.avatar();
          }
          if (res.data.defaultClinic) {
            this.loginForm.clinicId = res.data.defaultClinic;
          }
          if (res.data.clinics) {
            this.clinicList = res.data.clinics;
            // this.$store.commit("setChangeAbleHospitalInfo", res.data.clinics);
          } else {
            this.clinicList = [];
            // this.$store.commit("setChangeAbleHospitalInfo", []);
          }
          if (res.data.isNeedCaptcha) {
            this.loginForm.isNeedCaptcha = true;
            this.loginForm.captchaReason = res.data.captchaReason;
            this.refreshCaptcha();
          }
        } else {
          this.setInit();
        }
      });
    },
    setInit() {
      this.loginForm.avatar = this.avatar();
      this.clinicList = [];
      this.loginForm.isNeedCaptcha = false;
      this.loginForm.clinicId = undefined;
      this.loginForm.captchaReason = "";
    },
    reset() {
      this.$refs.loginForm.resetFields();
      this.getPreInfo();
    },
    // 切换主题
    onThemeChange: function (themeColor) {
      this.$store.commit("setThemeColor", themeColor);
    },
    compile(code) {
      var c = String.fromCharCode(code.charCodeAt(0) + code.length);
      for (var i = 1; i < code.length; i++) {
        c += String.fromCharCode(code.charCodeAt(i) + code.charCodeAt(i - 1));
      }
      return escape(c);
    },
    uncompile(code) {
      code = unescape(code);
      var c = String.fromCharCode(code.charCodeAt(0) - code.length);
      for (var i = 1; i < code.length; i++) {
        c += String.fromCharCode(code.charCodeAt(i) - c.charCodeAt(i - 1));
      }
      return c;
    },
  },
  computed: {
    ...mapState({
      themeColor: (state) => state.app.themeColor,
    }),
  },
};
</script>

<style lang="less" scoped>
.login-container {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin: 15vh 0 20vh 5vw;
  width: 400px;
  padding: 0;
  background: #FFF8DC;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;

  .remember {
    margin: 0px 0px 35px 0px;
  }
}

.login-main {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background-image: url("~@/assets/image/loginBack.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 90px;
}
</style>