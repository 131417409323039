<template>
  <div class="home-main">
    <div v-html="homeNotice"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      homeNotice: "",
    }
  },
  mounted() {
    this.findData();
  },
  methods: {
    findData() {
      this.loading = true;
      this.$api.erp.homePage
        .findHome()
        .then((res) => {
          this.homeNotice = res.data.content;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  }
}
</script>

<style scoped>
.home-main {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0;
  padding: 20px;
  background-image: url("~@/assets/image/homeBackground.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>