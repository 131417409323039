import axios from '../../../axios'

/* 
 * 病人来源模块
 */

const preUrl = "/erp/visit/";

// 获取挂号来源列表
export const directVisitList = (params) => {
    return axios({
        url: preUrl + 'directVisitList',
        method: 'get',
        params
    })
}

export const saleList = (params) => {
    return axios({
        url: preUrl + 'saleList',
        method: 'get',
        params
    })
}

export const doctorVisitList = (params) => {
    return axios({
        url: preUrl + 'doctorVisitList',
        method: 'get',
        params
    })
}

export const findVisitPatient = (params) => {
    return axios({
        url: preUrl + 'findVisitPatient',
        method: 'get',
        params
    })
}

export const findVisitRegistered = (params) => {
    return axios({
        url: preUrl + 'findVisitRegistered',
        method: 'get',
        params
    })
}

export const findDoctorAgreement = (params) => {
    return axios({
        url: preUrl + 'findDoctorAgreement',
        method: 'get',
        params
    })
}

// 获取就诊药品
export const findVisitMedicine = (params) => {
    return axios({
        url: preUrl + 'findVisitMedicine',
        method: 'get',
        params
    })
}

// 获取药品详情
export const findMedicineDetails = (params) => {
    return axios({
        url: preUrl + 'findMedicineDetails',
        method: 'get',
        params
    })
}
export const findMedicineDetailsBatch = (data) => {
    return axios({
        url: preUrl + 'findMedicineDetailsBatch',
        method: 'post',
        data
    })
}

// 保存处方
export const savePrescription = (data) => {
    return axios({
        url: preUrl + 'savePrescription',
        method: 'post',
        data
    })
}

export const completeClinic = (data) => {
    return axios({
        url: preUrl + 'completeClinic',
        method: 'post',
        data
    })
}

export const changeToMainName = (data) => {
    return axios({
        url: preUrl + 'changeToMainName',
        method: 'post',
        data
    })
}

// 删除处方
export const deletePrescription = (params) => {
    return axios({
        url: preUrl + 'deletePrescription',
        method: 'delete',
        params
    })
}

// 获取当前就诊处方
export const getNowPrescription = (params) => {
    return axios({
        url: preUrl + 'getNowPrescription',
        method: 'get',
        params
    })
}

// 获取历史处方
export const getHistoryPrescription = (params) => {
    return axios({
        url: preUrl + 'getHistoryPrescription',
        method: 'get',
        params
    })
}

export const getHistoryDetails = (params) => {
    return axios({
        url: preUrl + 'getHistoryDetails',
        method: 'get',
        params
    })
}

export const findCommonDoctorAdvice = (params) => {
    return axios({
        url: preUrl + 'findCommonDoctorAdvice',
        method: 'get',
        params
    })
}

export const findCommonIllness = (params) => {
    return axios({
        url: preUrl + 'findCommonIllness',
        method: 'get',
        params
    })
}

export const findCommonReported = (params) => {
    return axios({
        url: preUrl + 'findCommonReported',
        method: 'get',
        params
    })
}