import axios from '../../../axios'

/* 
 * 医生模块
 */

const preUrl = "/erp/stockInventory/";

// 分页查找
export const findPage = (data) => {
  return axios({
      url: preUrl + 'findPage',
      method: 'post',
      data
  })
}

// Excel导出
export const excelExport = (data) => {
  return axios({
      url: preUrl + 'excelExport',
      method: 'post',
      data,
      responseType: "blob",
  })
}

// 导出盘点明细
export const inventoryExcelExport = (params) => {
  return axios({
      url: preUrl + 'inventoryExcelExport',
      method: 'post',
      params,
      responseType: "blob",
  })
}


// 获取正在进行中的盘点列表
export const findNow = (params) => {
  return axios({
      url: preUrl + 'findNow',
      method: 'get',
      params
  })
}

// 查询详细
export const findDetailsById = (params) => {
  return axios({
      url: preUrl + 'findDetailsById',
      method: 'get',
      params
  })
}

// 发起盘点
export const launchInventory= (data) => {
  return axios({
      url: preUrl + 'launchInventory',
      method: 'post',
      data
  })
}

// 添加盘点药品
export const addRecord = (data) => {
  return axios({
      url: preUrl + 'addRecord',
      method: 'post',
      data
  })
}

// 删除盘点药品
export const delRecord= (params) => {
  return axios({
      url: preUrl + 'delRecord',
      method: 'delete',
      params
  })
}

// 结束盘点
export const inventoryFinish = (params) => {
  return axios({
      url: preUrl + 'inventoryFinish',
      method: 'patch',
      params
  })
}

// 撤销本次盘点
export const delInventory= (params) => {
  return axios({
      url: preUrl + 'delInventory',
      method: 'delete',
      params
  })
}