import axios from '../../../axios'

/* 
 * 财务统计模块 
 */

const preUrl = "/erp/accountant/";

// 财务统计-概览
export const overview= (params) => {
  return axios({
      url: preUrl + 'overview',
      method: 'get',
      params
  })
}

// 财务统计-收费项目汇总
export const feeCollection= (params) => {
  return axios({
      url: preUrl + 'feeCollection',
      method: 'get',
      params
  })
}

// 财务统计-业绩完成统计
export const performance= (params) => {
  return axios({
      url: preUrl + 'performance',
      method: 'get',
      params
  })
}

// 获取财务统计的医馆
export const getAccountantClinicList= () => {
  return axios({
      url: preUrl + 'getAccountantClinicList',
      method: 'get'
  })
}