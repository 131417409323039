<template>
  <el-autocomplete :debounce="0" :placeholder="placeholder" v-model="childValue" :fetch-suggestions="querySearch"
    @select="selectSearch" :trigger-on-focus="false" size="mini">
    <template slot-scope="{ item }">
      <div class="item-line">
        <span v-for="(lable, index) in items" :key="index">{{ getObjectValue(item, lable) }}</span>
      </div>
    </template>
  </el-autocomplete>
</template>

<script>
export default {
  props: {
    value: String,
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    api: {
      type: String,
      require: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    params: Object,
  },
  data() {
    return {
      childValue: this.value,
      dataList: [],
    }
  },
  watch: {
    value(value) {
      this.childValue = value;
    },
  },
  mounted() {
  },
  methods: {
    querySearch(queryString, cb) {
      if (!queryString) {
        cb([]);
        return;
      }
      let params = this.params;
      if (!params) params = {};
      params.condition = queryString;
      this.getObjectValue(this.$api, this.api)(params)
        .then((res) => {
          if (res.data) {
            cb(res.data);
          } else {
            cb([]);
          }
        });
    },
    selectSearch(item) {
      this.$emit("checked", item);
      this.$emit("input", this.childValue);
    },
  }
}
</script>

<style scoped>
.item-line>span:not(:first-child) {
  margin-left: 10px;
}
</style>
