import axios from '../../../axios'

/* 
 * 医生模块
 */

const preUrl = "/erp/doctor/"; 

// 分页查找
export const findPage = (data) => {
    return axios({
        url: preUrl + 'findPage',
        method: 'post',
        data
    })
}

// Excel导出
export const excelExport = (data) => {
    return axios({
        url: preUrl + 'excelExport',
        method: 'post',
        data,
        responseType: "blob",
    })
}

// 查询详细
export const findDetailsById = (params) => {
    return axios({
        url: preUrl + 'findDetailsById',
        method: 'get',
        params
    })
}

// 保存编辑或新增
export const save= (data) => {
  return axios({
      url: preUrl + 'save',
      method: 'post',
      data
  })
}

// 逻辑删除
export const logicalDelete= (params) => {
  return axios({
      url: preUrl + 'logicalDelete',
      method: 'delete',
      params
  })
}

// 获取挂号医生列表
export const findActiveList= () => {
    return axios({
        url: preUrl + 'findActiveList',
        method: 'get'
    })
}
