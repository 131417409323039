import axios from '../../../axios'

const preUrl = "/erp/refund/";

export const findRefundInfo = (params) => {
    return axios({
        url: preUrl + 'findRefundInfo',
        method: 'get',
        params
    })
}

export const submitRefund = (data) => {
    return axios({
        url: preUrl + 'submitRefund',
        method: 'post',
        data
    })
}

export const secondRefund = (data) => {
    return axios({
        url: preUrl + 'secondRefund',
        method: 'post',
        data
    })
}

export const findApplyList = (params) => {
    return axios({
        url: preUrl + 'findApplyList',
        method: 'get',
        params
    })
}

export const findApplyDetails = (params) => {
    return axios({
        url: preUrl + 'findApplyDetails',
        method: 'get',
        params
    })
}

export const handleApply = (data) => {
    return axios({
        url: preUrl + 'handleApply',
        method: 'post',
        data
    })
}

export const findRecordList = (params) => {
    return axios({
        url: preUrl + 'findRecordList',
        method: 'get',
        params
    })
}