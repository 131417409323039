import axios from '../../axios'
/* 
 * 系统设置模块
 */

const preUrl = "/auth/system/";

// 权限热刷新
export const refrashResource = () => {
  return axios({
    url: preUrl + 'refrashResource',
    method: 'post',
})}
