<template>
  <div class="my-info-item">
    <div class="title">{{ title }}</div>
    <div v-show="type=='normal'" class="value">：{{ value }}<slot></slot></div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    value:[String, Number],
    type:{
      type:String,
      default:"normal"
    }
  }
}
</script>

<style lang="less" scoped>
.my-info-item {
  display: flex;
  min-width: 300px;
  line-height: 30px;

  >.title {
    min-width: 100px;
    text-align: right;
    font-size: 16px;
  }
}
</style>