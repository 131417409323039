<!--详情界面-->
<template>
  <el-dialog title="插件打印设置" :fullscreen="true" :visible.sync="isVisible" :append-to-body="true"
    :modal-append-to-body="true" :close-on-click-modal="false" :before-close="dialogClose" v-loading="loading"
    element-loading-text="插件连接中">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="16" class="work-hose" style="height:85vh; overflow-y:scroll;">
        <el-card v-for="(item, index) in printSettingList" :key="index" class="box-card mbot-30"
          :body-style="{ padding: '10px' }">
          <el-row slot="header">
            <el-col :span="6" align="left">
              <span style="font-size:20px; margin-right:100px;" class="f-bold">{{ item.title }}</span>
            </el-col>
            <el-col :span="12">
              <span class="mlef-30">
                <span v-show="!item.exist"><i class="el-icon-warning color-info"></i>未连接</span>
                <span v-show="item.exist">
                  <i class="el-icon-success color-success"></i>
                  <span>已连接到 {{ item.hostInfo.description }} 2.{{ item.hostInfo.version }}</span>
                  <el-tag v-if="item.hostInfo.version == global.nowLocalServerVersion" type="success">最新版本</el-tag>
                  <el-tag
                    v-if="item.hostInfo.version < global.nowLocalServerVersion && item.hostInfo.version >= item.minVersion">
                    可升级</el-tag>
                  <el-tag v-if="item.hostInfo.version < item.minVersion" type="danger">需升级</el-tag>
                  <el-button @click="softDownload()" v-if="global.nowLocalServerVersion > item.hostInfo.version"
                    type="text" class="clear-pm" icon="el-icon-download"></el-button>
                </span>
              </span>
            </el-col>
            <el-col :span="6">
              <el-button @click="reverseSetting(item)" size="small" style="padding:5px" icon="el-icon-refresh-left"
                type="danger">重置
              </el-button>
              <el-button @click="saveSetting(item)" size="small" style="padding:5px" icon="el-icon-check"
                type="primary">保存设置</el-button>
            </el-col>
          </el-row>
          <div class="of-hidden">
            <div class="of-hidden">
              <!-- <div>主机设置</div> -->
              <div class="mtop-5">
                <span>IP地址：</span>
                <el-input size="small" :disabled="item.setting.host == 'localhost'" style="width:150px;"
                  v-model="item.setting.host" placeholder="" />
                <el-checkbox class="mlef-10" v-model="item.setting.host" true-label="localhost" false-label="192.168.">
                  <span>本机地址</span>
                </el-checkbox>
                <el-button class="mlef-50" style="padding:5px" size="small" icon="el-icon-magic-stick" type="success"
                  @click="printConnectTest(item)">连接
                </el-button>
              </div>
            </div>
            <hr />
            <div class="of-hidden mtop-5">
              <!-- <div>打印设置</div> -->
              <div class="mtop-5">
                <div class="fl-left" style="width:300px">
                  <span>打印机：</span>
                  <el-select :disabled="!item.exist" v-model="item.setting.printerName" size="small"
                    style="width:230px">
                    <el-option v-for="(item, index) in item.printerList" :key="index" :label="item" :value="item">
                    </el-option>
                  </el-select>
                </div>
                <div class="fl-left mlef-20" style="width:170px">
                  <span>左边距：</span>
                  <el-input :disabled="!item.exist" style="width:60px;" v-model="item.setting.marginLeft" size="small">
                  </el-input>
                  <span>毫米</span>
                </div>
                <div class="fl-left mlef-20" style="width:170px">
                  <span>上边距：</span>
                  <el-input :disabled="!item.exist" style="width:60px;" v-model="item.setting.marginTop" size="small">
                  </el-input>
                  <span>毫米</span>
                </div>
                <div class="fl-left mlef-20">
                  <el-button :disabled="!item.exist" size="mini" style="padding:5px" type="success"
                    @click="printTest(item)" icon="el-icon-printer">测试</el-button>
                </div>
                <div class="fl-left mlef-20">
                  <el-checkbox :disabled="!item.exist" class="mlef-10" v-model="item.setting.autoPrint">
                    <span>自动打印</span>
                  </el-checkbox>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="4" :xl="8">
        <div>
          <div>提示：</div>
          <div class="mlef-30">
            <div class="mtop-10">1.设置步骤：设置IP地址 ---> 点击连接 ---> 选择打印机 ---> 测试打印 ---> 保存设置</div>
            <div class="mtop-10">2.可以通过IP地址连接同一局域网内其它电脑的打印插件，无需设置打印共享！</div>
            <div class="mtop-10">3.如果连接不上打印插件，请检查插件是否启动（每次插件启动时都会弹框提示），如果没启动，可以点击桌面图标启动插件，或者重新下载安装。</div>
            <div class="mtop-10">4.如果连接不上同一网络上其它电脑的打印插件，确保插件正常启动的情况下，请检查IP地址是否正确、是否被网络防火墙拦截。</div>
            <div class="mtop-10">5.安装过程360软件可能会拦截开机启动，请将其设置为允许！</div>
          </div>
        </div>
        <div class="mtop-20">
          <el-button @click="softDownload()" type="text" class="clear-pm mtop-10" icon="el-icon-download">点击下载插件
            V2.{{ global.nowLocalServerVersion }}</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 对话框底部按钮 -->
    <div slot="footer" class="dialog-footer" style="margin-top: -30px">
      <el-button @click.native="dialogClose()">关闭</el-button>
    </div>
  </el-dialog>
</template>


<script>
import axios from 'axios';
export default {
  data() {
    return {
      isVisible: true,
      loading: false,
      downloadUrl: "https://wei-1258896086.cos.ap-shanghai.myqcloud.com/download/%E6%B5%8E%E4%B8%96%E5%A0%82%E6%94%B6%E8%B4%B9%E7%B3%BB%E7%BB%9F%E6%8F%92%E4%BB%B6.exe",
      printSettingList: []
    };
  },
  computed: {
    paramPrefix() {
      return this.$store.state.app.printParamPrefix;
    }
  },
  mounted() {
    this.getLocalSetting();
  },
  methods: {
    dialogClose() {
      this.$emit("close");
    },
    getLocalSetting() {
      const list = JSON.parse(JSON.stringify(this.$store.state.app.printSettingList));
      for (let i = 0, length = list.length; i < length; i++) {
        let item = list[i];
        item.setting = JSON.parse(JSON.stringify(item.default));
        let local = localStorage.getItem(this.paramPrefix + item.name);
        console.log("本地设置" + item.name);
        console.log(local);
        if (local && local != undefined) {
          try {
            let setting = JSON.parse(local);
            if (setting.host && setting.printerName) {
              item.setting = setting;
              this.printConnectTest(item);
            }
          } catch (e) { };
        }
      }
      this.printSettingList = list;
    },
    printConnectTest(item) {
      if (!item.setting.host) {
        this.warnMsg("请填写IP地址！");
        return;
      }
      this.loading = true;
      this.$api.localServer.print
        .getPrinterList(item.setting.host)
        .then((res) => {
          this.loading = false;
          if (item.minVersion > res.stat.version) {
            this.errMsg("插件版本过低，请升级后重试！");
            this.clearConnect(item);
          } else {
            this.succMsg(res.msg);
            item.exist = true;
            item.printerList = res.data;
            item.hostInfo = res.stat;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.clearConnect(item);
        });
    },
    clearConnect(item) {
      item.exist = false;
      item.printerList = [];
      item.hostInfo = {};
    },
    printTest(item) {
      if (!item.setting.printerName) {
        this.warnMsg("请选择打印机！");
        return;
      }
      let data = item.testData;
      data.setting = {
        marginLeft: item.setting.marginLeft,
        marginTop: item.setting.marginTop,
        printerName: item.setting.printerName,
      };
      this.$api.localServer.print[item.name](data, item.setting.host)
        .then((res) => {
          this.succMsg(res.msg);
        });
    },
    softDownload() {
      window.open(this.downloadUrl, "_blank");
    },
    reverseSetting(item) {
      item.setting = item.default;
      localStorage.setItem(this.paramPrefix + item.name, JSON.stringify(item.setting));
      this.succMsg("重置成功！");
    },
    saveSetting(item) {
      if (!item.exist) {
        this.warnMsg("请先连接！");
        return;
      }
      if (!item.setting.printerName) {
        this.warnMsg("请选择打印机！");
        return;
      }
      localStorage.setItem(this.paramPrefix + item.name, JSON.stringify(item.setting));
      this.succMsg("保存成功！");
    }
  },
};
</script>

<style scoped>
</style>