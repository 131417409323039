import axios from '../../../axios'

/* 
 * 医生坐诊模块
 */

const preUrl = "/erp/doctorClinic/";

// 分页查找
export const findPage = (data) => {
    return axios({
        url: preUrl + 'findPage',
        method: 'post',
        data
    })
}

export const findNotClinicList = () => {
    return axios({
        url: preUrl + 'findNotClinicList',
        method: 'get'
    })
}

export const findByDoctor = (params) => {
    return axios({
        url: preUrl + 'findByDoctor',
        method: 'get',
        params
    })
}

export const save = (data) => {
    return axios({
        url: preUrl + 'save',
        method: 'post',
        data
    })
}

export const updateUseful = (data) => {
    return axios({
        url: preUrl + 'updateUseful',
        method: 'post',
        data
    })
}


export const deleteData = (params) => {
  return axios({
      url: preUrl + 'deleteData',
      method: 'delete',
      params
  })
}

export const findRegisteredDoctor = () => {
    return axios({
        url: preUrl + 'findRegisteredDoctor',
        method: 'get',
    })
}

export const findRegisteredDoctorDetails = (params) => {
  return axios({
      url: preUrl + 'findRegisteredDoctorDetails',
      method: 'get',
      params
  })
}

export const findActiveList = (params) => {
    return axios({
        url: preUrl + 'findActiveList',
        method: 'get',
        params
    })
}

export const findCascaderList = (params) => {
    return axios({
        url: preUrl + 'findCascaderList',
        method: 'get',
        params
    })
}

export const findDoctorListByClinic = (params) => {
    return axios({
        url: preUrl + 'findDoctorListByClinic',
        method: 'get',
        params
    })
}

export const findForSchedule = (params) => {
    return axios({
        url: preUrl + 'findForSchedule',
        method: 'get',
        params
    })
}

