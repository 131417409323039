import axios from '../../../axios'

/* 
 * 医馆模块
 */

const preUrl = "/erp/locationLog/";

export const dealData = () => {
  return axios({
      url: preUrl + 'dealData',
      method: 'get'
  })
}

export const findList = (data) => {
  return axios({
      url: preUrl + 'findList',
      method: 'post',
      data
  })
}