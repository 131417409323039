import axios from 'axios';
import config from './config';
import router from '@/router'
import global from '@/utils/global';
import {
  Message
} from 'element-ui';

export default function $axios(options) {
  return new Promise((resolve, reject) => {
    const instance = axios.create({
      baseURL: config.baseUrl,
      headers: config.headers,
      timeout: config.timeout,
      withCredentials: config.withCredentials
    })
    // request 请求拦截器
    instance.interceptors.request.use(
      config => {
        let token = sessionStorage.getItem(global.localTokenName);
        // 发送请求时携带token
        if (token) {
          config.headers.Authorization = token
        } else {
          // 重定向到登录页面
          router.push('/login')
        }
        return config
      },
      error => {
        // 请求发生错误时
        console.log('request:', error)
        // 判断请求超时
        if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
          console.log('timeout请求超时')
        }
        // 需要重定向到错误页面
        const errorInfo = error.response
        console.log(errorInfo)
        if (errorInfo) {
          error = errorInfo.data // 页面那边catch的时候就能拿到详细的错误信息,看最下边的Promise.reject
          const errorStatus = errorInfo.status; // 404 403 500 ...
          router.push({
            path: `/error/${errorStatus}`
          })
        }
        return Promise.reject(error) // 在调用的那边可以拿到(catch)你想返回的错误信息
      }
    )

    // response 响应拦截器
    instance.interceptors.response.use(
      response => { // http请求成功
        // console.log(response);
        if (response.headers.filetag && response.headers.filetag == "excel") {
          console.log(response);
          const stream = response.data; // 后端用stream返回Excel文件
          const blob = new Blob([stream]);
          const fileName = response.headers["filename"];

          // 前端获取业务码，成功执行正常业务
          const downloadElement = document.createElement('a');
          const href = window.URL.createObjectURL(blob); // 创建下载的链接
          downloadElement.href = href;
          downloadElement.download = decodeURIComponent(fileName); // 下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); // 点击下载
          document.body.removeChild(downloadElement); // 下载完成移除元素
          window.URL.revokeObjectURL(href); // 释放掉blob对象

        } else if (response.data.code != 200) { // 1->数据请求失败
          // 控制台输出错误信息
          console.log(response.data);
          // 前端输出错误消息
          if (response.data.msg) {
            Message({
              message: response.data.msg,
              type: 'error'
            })
          } else {
            Message({
              message: "后端出错",
              type: 'error'
            })
          }

          // 用户未登录时处理
          if (response.data.code == 401) {
            sessionStorage.removeItem("user");
            router.push({
              path: `/login`
            })
          }
          return Promise.reject(response.data)
        } else {
          return response.data
        }
      },
      error => { // http请求失败
        if (error && error.response && error.response.data) {
          switch (error.response.data.status) {
            case 400:
              error.msg = '400后端数据格式不支持！'
              break
            case 401:
              error.msg = '401未授权，请登录'
              break
            case 403:
              error.msg = '403权限不足，拒绝访问'
              break
            case 404:
              error.msg = `404请求地址出错: ${error.response.config.url}`
              break
            case 405:
              error.msg = '405请求方法不正确(post和get用反)！'
              break
            case 408:
              error.msg = '408请求超时'
              break
            case 500:
              error.msg = '500服务器内部错误'
              break
            case 501:
              error.msg = '501服务未实现'
              break
            case 502:
              error.msg = '502网关错误'
              break
            case 503:
              error.msg = '503服务不可用'
              break
            case 504:
              error.msg = '504网关超时'
              break
            case 505:
              error.msg = '505HTTP版本不受支持'
              break
            default: {
              if (error.response.data.msg) {
                error.msg = error.response.data.msg;
              } else {
                error.msg = '网络错误' + error.response.data;
              }
            }
          }
        } else if (error && error.message) {
          error.msg = "网络错误，请检查服务器连接！" + error;
        } else if (error) {
          error.msg = "网络错误" + error;
        } else {
          error = {
            msg: "网络请求出错！"
          };
        }
        Message({
          message: error.msg,
          type: 'error'
        })
        console.log(error);
        return Promise.reject(error) // 返回接口返回的错误信息
      }
    )
    // 请求处理
    instance(options).then(res => {
      resolve(res)
      return false
    }).catch(error => {
      console.log(error);
      reject(error)
    })
  })
}