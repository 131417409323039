<template>
  <div>
    {{ label }}：
    <el-select v-if="type == 'select'" :size="size" placeholder="" @change="itemCheck()" v-model="childValue"
      :multiple="multiple" filterable>
      <el-option v-if="!multiple && filterAll" label="不限" :value="-100" />
      <el-option v-for="item in dataList" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
    <el-date-picker v-if="type == 'daterange'" @change="itemCheck()" v-model="childValue" type="daterange" unlink-panels
      :clearable="false" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%"
      :size="size" />
    <el-date-picker v-if="type == 'date'" :size="size" style="width: 100%" v-model="childValue" @change="itemCheck()"
      type="date" :clearable="false">
    </el-date-picker>
    <el-input v-if="type == 'input'" :size="size" placeholder="" @change="itemCheck()" v-model="childValue" />
  </div>
</template>

<script>
export default {
  props: {
    value: [Number, String, Date, Array],
    type: {
      type: String,
      default: 'select',
    },
    size: {
      type: String,
      default: 'medium',
    },
    label: String,
    filterAll: {
      type: Boolean,
      default: true
    },
    listType: {
      type: String,
      default: 'api',
    }, 
    multiple: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    api: String,
    params: Object,
  },
  data() {
    return {
      childValue: this.value,
      dataList: [],
    }
  },
  watch: {
    value(value) {
      this.childValue = value;
    },
  },
  mounted() {
    if (this.type == "select") {
      switch (this.listType) {
        case "api": this.findFilterItem(); break;
        case "list": this.dataList = JSON.parse(JSON.stringify(this.items)); break;
        case "isOrNot": this.dataList = [{ id: 1, name: '是' }, { id: 0, name: '否' }]; break
        case "useful": this.dataList = [{ id: 1, name: '启用' }, { id: 0, name: '停用' }]; break
      }
    }
  },
  methods: {
    findFilterItem() {
      this.getObjectValue(this.$api, this.api)(this.params)
        .then((res) => {
          let data = JSON.parse(JSON.stringify(res.data));
          this.dataList = data;
        });
    },
    itemCheck() {
      this.$emit("input", this.childValue);
      this.$emit("change", true);
    },
  }
}
</script>

<style lang="less" scoped>
.select-box {
  max-height: 500px;
  overflow: auto;

  .item {
    display: block;
    color: black;
    text-decoration: none;
    line-height: 25px;

    &:hover {
      background-color: bisque;
    }

  }


  >.item {
    padding-left: 10px;
  }

  >.group {
    border-top: 1px solid #aaa;
    display: flex;

    >.abbr {
      width: 20px;
      color: indigo;
    }

    >.items {
      flex-grow: 1;
    }
  }

  .active {
    color: #409EFF;
    font-weight: bold;
  }
}
</style>
