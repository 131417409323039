import axios from '../../../axios'

/* 
 * 药库模块
 */

const preUrl = "/erp/warehouse/";

export const findActiveList = () => {
  return axios({
    url: preUrl + 'findActiveList',
    method: 'get'
  })
}

export const findList = () => {
  return axios({
    url: preUrl + 'findList',
    method: 'get'
  })
}

export const findTreeData = () => {
  return axios({
    url: preUrl + 'findTreeData',
    method: 'get'
  })
}

export const save = (data) => {
  return axios({
    url: preUrl + 'save',
    method: 'post',
    data
  })
}

export const logicalDelete = (params) => {
  return axios({
    url: preUrl + 'logicalDelete',
    method: 'delete',
    params
  })
}

export const findPrescriptionTypeBindList = () => {
  return axios({
    url: preUrl + 'findPrescriptionTypeBindList',
    method: 'get'
  })
}

export const savePrescriptionTypeBind = (data) => {
  return axios({
    url: preUrl + 'savePrescriptionTypeBind',
    method: 'post',
    data
  })
}