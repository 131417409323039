export default {
  state: {
    isDispenseList: [
      { id: 0, name: '未发药', color: '#F56C6C' },
      { id: 1, name: '已发药', color: '#303133' }, 
      { id: 2, name: '部分发药', color: '#303133' }, 
      { id: -1, name: '已退药', color: '#303133' },
    ],
    chargeStatus: [
      { id: 1, name: "已收费" }, 
      { id: 0, name: "未收费" }, 
      { id: -1, name: "已退费" }
    ],
    visitStatusList: [
      { id: 0, name: "未就诊" }, 
      { id: 1, name: "未缴费" }, 
      { id: 2, name: "已缴费" }
    ],
    dispenseStatus: [
      { id: 1, name: "已发药" }, 
      { id: 0, name: "未发药" }, 
      { id: -1, name: "已退药" }, 
      { id: 2, name: "无需发药" }
    ],
    AllotStatus: [
      { id: 1, name: "调出处方" }, 
      { id: 2, name: "调入处方" }, 
    ],
    channelList: [
      { id: 1, name: "现场挂号" }, 
      { id: 2, name: "微信挂号" }
    ],
    registeredUsefulList: [
      { id: 1, name: "正常" }, 
      { id: -1, name: "已作废" }
    ],
    memberPackScope:[
      { id: 1, name: "所有馆可用" },
      { id: 2, name: "当前馆可用" },
      { id: 3, name: "当前科室可用" },
      { id: 4, name: "指定医生可用" },
    ],   
    memberContentType:[
      { id: 1, name: "立减" },
      { id: 2, name: "折扣" },
      { id: 3, name: "限价" },
    ],
    memberContentScope:[
      { id: 0, name: "不限" },
      { id: 1, name: "挂号费" },
      { id: 2, name: "药品" },
      { id: 3, name: "诊疗" },
      { id: 4, name: "加工费" },
      { id: 5, name: "消费项目" },
    ],
    meberOrderType:[
      { id: 11, name: "套餐卡充值" },
      { id: 12, name: "套餐卡退费" },
      { id: 21, name: "记账还款" },
      { id: 31, name: "诊疗消费" },
      { id: 32, name: "诊疗退费" },
    ],
    memberOrderPayType:[
      { id: 1, name: "代收费用" },
      { id: 2, name: "推送收费处" },
    ]
  },
  getters: {
    // 通过状态值查找普通标签
    getIsDispenseById: state => (id) => {
      var item = state.isDispenseList.find(todo => todo.id == id);
      return item ? item : {
        name: ''
      };
    },
  },
  mutations: {},
  actions: {}
}
