import axios from '../../axios'
import qs from 'qs' // 使用qs对post传送的据序列化
/* 
 * 系统登录模块
 * 此目录的URL无需权限可访问
 */

const preUrl = "/auth/oauth/";

// 登录
export const login = data => {
    return axios({
        url: preUrl + 'token',
        method: 'post',
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        }
    })
}

// 获取验证码
export const captcha = () => {
  return axios({
      url: preUrl + 'captcha',
      method: 'get'
  })
}

// 获取预登录信息
export const getPreInfo = (params) => {
  return axios({
      url: preUrl + 'preInfo',
      method: 'get',
      params
  })
}

// 登出*
export const logout = () => {
    return axios({
        url: 'logout',
        method: 'get'
    })
}
