import axios from '../../../axios'

/* 
 * 药库药品模块
 */

const preUrl = "/erp/warehouseMedicine/";

// 分页查找
export const findPage = (data) => {
    return axios({
        url: preUrl + 'findPage',
        method: 'post',
        data
    })
  }
  
  // Excel导出
  export const excelExport = (data) => {
      return axios({
          url: preUrl + 'excelExport',
          method: 'post',
          data,
          responseType: "blob",
      })
  }
  
  // 保存
  export const save = (data) => {
      return axios({
          url: preUrl + 'save',
          method: 'post',
          data
      })
  }
  
  export const findDetailsById= (params) => {
    return axios({
        url: preUrl + 'findDetailsById',
        method: 'get',
        params
    })
  }
  
  export const logicalDelete= (params) => {
    return axios({
        url: preUrl + 'logicalDelete',
        method: 'delete',
        params
    })
  }

  // 拼音码查询出入库药品
  export const findByPinyin= (params) => {
    return axios({
        url: preUrl + 'findByPinyin',
        method: 'get',
        params
    })
  }
  
  export const findByPinyinOnlyName= (params) => {
    return axios({
        url: preUrl + 'findByPinyinOnlyName',
        method: 'get',
        params
    })
  }
  
  export const findVisitStock= (params) => {
    return axios({
        url: preUrl + 'findVisitStock',
        method: 'get',
        params
    })
  }

  // 更新所有药品的库存、平均进价等信息
  export const updateAllMedicineInfo= (params) => {
    return axios({
        url: preUrl + 'updateAllMedicineInfo',
        method: 'get',
        params
    })
  }
  
  export const changeInfoBind = (data) => {
    return axios({
        url: preUrl + 'changeInfoBind',
        method: 'post',
        data
    })
}

export const conditionQuery= (params) => {
  return axios({
      url: preUrl + 'conditionQuery',
      method: 'get',
      params
  })
}

export const findProcessList= (params) => {
  return axios({
      url: preUrl + 'findProcessList',
      method: 'get',
      params
  })
}
  
  