import axios from '../../../axios'

/* 
 * 挂号模块
 */

const preUrl = "/erp/registered/";

// 获取挂号医生列表
export const getRegisteredDoctor= () => {
    return axios({
        url: preUrl + 'getRegisteredDoctor',
        method: 'get'
    })
}

// 挂号
export const registered = (data) => {
    return axios({
        url: preUrl + 'registered',
        method: 'post',
        data
    })
}

// 挂号
export const billRegistered = (data) => {
    return axios({
        url: preUrl + 'billRegistered',
        method: 'post',
        data
    })
}

// 修改挂号信息
export const updateInfo = (data) => {
    return axios({
        url: preUrl + 'updateInfo',
        method: 'patch',
        data
    })
}

// 挂号作废
export const obsolete = (params) => {
    return axios({
        url: preUrl + 'obsolete',
        method: 'patch',
        params
    })
}


// 分页查找
export const findPage = (data) => {
    return axios({
        url: preUrl + 'findPage',
        method: 'post',
        data
    })
}

// Excel导出
export const excelExport = (data) => {
    return axios({
        url: preUrl + 'excelExport',
        method: 'post',
        data,
        responseType: "blob",
    })
}

// 查询详细
export const findDetailsById = (params) => {
    return axios({
        url: preUrl + 'findDetailsById',
        method: 'get',
        params
    })
}

export const findPrintData = (params) => {
  return axios({
      url: preUrl + 'findPrintData',
      method: 'get',
      params
  })
}

// 挂号统计
export const statistics = (data) => {
    return axios({
        url: preUrl + 'statistics',
        method: 'post',
        data
    })
}

// 挂号统计
export const statisticsExcelExport = (data) => {
    return axios({
        url: preUrl + 'statisticsExcelExport',
        method: 'post',
        data,
        responseType: "blob",
    })
}

// 复诊提醒
export const reviewRemind = (params) => {
    return axios({
        url: preUrl + 'reviewRemind',
        method: 'get',
        params
    })
}

// 医生工作站复诊提醒
export const doctorReviewRemind = (params) => {
    return axios({
        url: preUrl + 'doctorReviewRemind',
        method: 'get',
        params
    })
}

// 复诊提醒导出
export const reviewRemindExcel = (params) => {
    return axios({
        url: preUrl + 'reviewRemindExcel',
        method: 'get',
        params,
        responseType: "blob",
    })
}

// 初复诊统计
export const firstStatisticsPage = (data) => {
    return axios({
        url: preUrl + 'firstStatisticsPage',
        method: 'post',
        data
    })
}

// 初复诊统计导出
export const firstStatisticsExcel = (data) => {
    return axios({
        url: preUrl + 'firstStatisticsExcel',
        method: 'post',
        data,
        responseType: "blob",
    })
}

// 流失患者统计
export const losePatientPage = (data) => {
    return axios({
        url: preUrl + 'losePatientPage',
        method: 'post',
        data
    })
}

// 流失患者统计导出
export const losePatientExcel = (data) => {
    return axios({
        url: preUrl + 'losePatientExcel',
        method: 'post',
        data,
        responseType: "blob",
    })
}


