/**
 * 全局方法
 */

var dayjs = require('dayjs')

exports.install = function (Vue, options) {
  /*-------------      权限判断     ------------*/
  Vue.prototype.hasPermission = function (perms) {
    let hasPermission = false
    let permissions = this.$store.state.user.perms
    for (let i = 0, len = permissions.length; i < len; i++) {
      if (permissions[i] === perms) {
        hasPermission = true;
        break
      }
    }
    return hasPermission
  }


  /*------------      消息提示     ------------*/
  // 成功消息提示
  Vue.prototype.succMsg = function (str) {
    this.$message({
      showClose: true,
      message: str,
      type: 'success'
    });
  }

  // 警告消息提示
  Vue.prototype.warnMsg = function (str) {
    this.$message({
      showClose: true,
      message: str,
      type: 'warning',
      duration: 3000
    });
  }

  // 错误消息提示
  Vue.prototype.errMsg = function (str) {
    this.$message({
      showClose: true,
      message: str,
      type: 'error',
      duration: 10000
    });
  }

  // 普通消息提示
  Vue.prototype.msg = function (str) {
    this.$message(str);
  }

  /*------------      时间、日期格式化     ------------*/
  // 日期格式化
  Vue.prototype.dateFormate = function (date) {
    if (!date) return "";
    else return dayjs(date).format('YYYY-MM-DD');
  }

  // 日期时间格式化
  Vue.prototype.dateTimeFormate = function (date) {
    if (!date) return "";
    else return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
  }

  // 日期时间格式化
  Vue.prototype.dateTMFormate = function (date) {
    if (!date) return "";
    else return dayjs(date).format('YYYY-MM-DD HH:mm');
  }

  // 年月格式化
  Vue.prototype.dateMonthFormate = function (date) {
    if (!date) return "";
    else return dayjs(date).format('MM-DD');
  }

  // Sql的Time类型时间格式化
  Vue.prototype.sqlTimeFormate = function (date) {
    if (!date) return "";
    else return dayjs("2022-03-18 " + date).format('HH:mm');
  }

  // 时间格式化
  Vue.prototype.timeFormate = function (date) {
    if (!date) return "";
    else return dayjs(date).format('HH:mm');
  }

  // 时间格式化
  Vue.prototype.timeSecondsFormate = function (date) {
    if (!date) return "";
    else return dayjs(date).format('HH:mm:ss');
  }

  // 时间格式化
  Vue.prototype.MinutesFormate = function (date) {
    if (!date) return "";
    else return dayjs(date).format('mm:ss');
  }

  // 微信预约时间段格式化
  Vue.prototype.timeRangeText = function (startTime, endTime) {
    if (!startTime && !endTime) return "全天";
    if (!startTime) return "截止到" + endTime;
    if (!endTime) return startTime + "开始";
    return startTime.substring(0, 5) + "~" + endTime.substring(0, 5);
  }

  // 微信预约状态
  Vue.prototype.reservationStatusText = function (item) {
    if (!item.id) return "";
    if (item.isRefund == 1) return "已退款";
    if (item.isRefund == 2) return "申请退款";
    if (item.isPaid) return "支付成功";
    if (item.useful) return "待支付";
    return "已取消";
  }

  // 日期范围字符串
  Vue.prototype.dateRangeString = function (dateRange) {
    return this.dateFormate(dateRange[0]) + "~" + this.dateFormate(dateRange[1]);
  }

  // 会员卡号格式化
  Vue.prototype.memberFormate = function (str) {
    if (this.notEmptyN(str)) {
      return str.toString().padStart(6, '0');
    } else {
      return "";
    }
  }

  Vue.prototype.emptyFormate = function (str) {
    return str ? str : '';
  }

  /*------------      表格数据格式化    ------------*/
  Vue.prototype.tableIsNot = function (row, column, cellValue, index) {
    return cellValue ? '是' : '否';
  }

  // 将0格式化换成空字符串
  Vue.prototype.tableZeroClear = function (row, column, cellValue, index) {
    return cellValue ? cellValue : '';
  }

  Vue.prototype.tableDateFormate = function (row, column, cellValue, index) {
    return cellValue ? Vue.prototype.dateFormate(cellValue) : '';
  }

  Vue.prototype.tableDateTimeFormate = function (row, column, cellValue, index) {
    return cellValue ? Vue.prototype.dateTimeFormate(cellValue) : '';
  }

  Vue.prototype.tableDateTMFormate = function (row, column, cellValue, index) {
    return cellValue ? Vue.prototype.dateTMFormate(cellValue) : '';
  }

  Vue.prototype.getObjectValue = function (obj, prop) {
    let props = prop.split(".");
    for (let i = 0; i < props.length; i++) {
      obj = obj[props[i]];
    }
    return obj;
  }

  Vue.prototype.formRuleInput = function () {
    return [{ required: true, message: "请输入", trigger: "blur" }];
  }

  Vue.prototype.formRuleSelect = function () {
    return [{ required: true, message: "请选择", trigger: "change" }];
  }

  /*------------      本地存储    ------------*/

  Vue.prototype.getLocalStorage = function (prop) {
    let data = undefined;
    let dataStr = localStorage.getItem(prop);
    if (dataStr) {
      try {
        data = JSON.parse(dataStr);
      } catch (e) {
        console.log("本地数据获取失败！" + prop);
      }
    }
    return data;
  }

  Vue.prototype.setLocalStorage = function (prop, data) {
    if (data) {
      localStorage.setItem("dispenseImmediatePrint", JSON.stringify(data));
    } else {
      localStorage.removeItem(prop);
    }
  }

  /*------------      数据排序    ------------*/
  // 正序
  Vue.prototype.dataSort = function (data, key) {
    return data.sort((a, b) => {
      var x = a[key];
      var y = b[key];
      x = x ? x : 0; // 把空置为0
      y = y ? y : 0;
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    })
  }

  // 倒序
  Vue.prototype.dataSortDesc = function (data, key) {
    return data.sort((a, b) => {
      var x = b[key];
      var y = a[key];
      x = x ? x : 0; // 把空置为0
      y = y ? y : 0;
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    })
  }
  // 字符串中文顺序排序
  Vue.prototype.stringSort = function (data, key) {
    return data.sort((a, b) => {
      let reg = /^[A-z]/;
      if (reg.test(a[key]) || reg.test(b[key])) {
        if (a[key] > b[key]) {
          return 1;
        } else if (a[key] < b[key]) {
          return -1;
        } else {
          return 0;
        }
      } else {
        return a[key].localeCompare(b[key], "zh");
      }
    })
  }

  /*------------      数据打包     ------------*/
  Vue.prototype.filterPackage = function (obj) {
    let data = {};
    for (let key in obj) {
      switch (key) {
        case "dateRange": {
          const dateRange = obj[key];
          data.startDate = this.dateFormate(dateRange[0]);
          data.endDate = this.dateFormate(dateRange[1]);
        };
          break;
        case "date":
          data[key] = this.dateFormate(obj[key]);
          break;
        default: {
          let item = obj[key];
          switch (typeof item) {
            case "number":
              if (item != -100) data[key] = item;
              break;
            case "string":
              if (item) data[key] = item;
              break;
            case "object": {
              if (item instanceof Date) {
                data[key] = this.dateFormate(item);
              } else if (item instanceof Array) {
                if (item.length > 0) data[key] = item;
              } else {
                console.log("[obj]" + key + ":" + item)
              }
            }
              break;
            default: {
              console.log(key + ":" + item)
            }
          }
        }
      }
    }
    return data;
  }


  /*------------      数据校验     ------------*/
  // 判断是否为空（无提示）
  Vue.prototype.notEmptyN = function (value) {

    if (typeof value == "undefined" || value == null || String(value) == "") {
      return false;
    } else {
      return true;
    }
  }


  // 判断是否为空
  Vue.prototype.notEmpty = function (value, name) {
    if (typeof value == "undefined" || value == null || String(value) == "") {
      this.warnMsg(name + '不能为空！');
      return false;
    } else {
      return true;
    }
  }

  // 判断是否为空或0（无提示）
  Vue.prototype.notEmptyZeroN = function (value) {

    if (typeof value == "undefined" || value == null || String(value) == "" || value == 0) {
      return false;
    } else {
      return true;
    }
  }

  // 判断是否为空
  Vue.prototype.notEmptyFull = function (value, name) {
    if (typeof value == "undefined" || value == null || String(value) == "") {
      this.warnMsg(name);
      return false;
    } else {
      return true;
    }
  }

  // 判断是否为空且大于零
  Vue.prototype.notEmptyPositive = function (value, name) {
    if (typeof value == "undefined" || value == null || value <= 0 || String(value) == "") {
      this.warnMsg(name + '不能为空且大于0！');
      return false;
    } else {
      return true;
    }
  }


  //判断不能为负数
  Vue.prototype.notPositive = function (value, name) {
    if (value < 0) {
      this.warnMsg(name + '不能为负数！');
      return false;
    } else {
      return true;
    }
  }

  //判断不能为负数
  Vue.prototype.Positive = function (value, name) {
    if (value != 0) {
      this.warnMsg(name + '不为零！');
      return false;
    } else {
      return true;
    }
  }

  //判断重复
  Vue.prototype.findRepetition = function (methods, name) {
    if (this.$store.getters[methods](name)) {
      this.warnMsg('名称重复！');
      return false;
    } else {
      return true;
    }
  }



  //判断余额
  Vue.prototype.monneyNotPositive = function (val1, val2, name) {
    var value1 = this.notEmptyN(val1) ? parseFloat(val1) : 0;
    var value2 = this.notEmptyN(val2) ? parseFloat(val2) : 0;
    if (value1 - value2 < 0) {
      this.warnMsg(name);
      return false;
    } else {
      return true;
    }
  }

  // 判断是否为空（是否选择）
  Vue.prototype.notSelect = function (value, name) {
    if (typeof value == "undefined" || value == null || String(value) == "") {
      this.warnMsg('请选择' + name + '！');
      return false;
    } else {
      return true;
    }
  }

  // 验证电话号码格式
  Vue.prototype.phoneNumber = function (value) {
    if (value && (!(/^[0-9]{6,11}$/).test(value))) {
      this.warnMsg('手机号码不符合规范');
      return false;
    } else {
      return true;
    }
  }
  //验证vip卡号必须是六位数字
  Vue.prototype.VIPNumber = function (value) {
    if (value && (!(/^[0-9]{6}$/).test(value))) {
      this.warnMsg('VIP卡号不符合规范');
      return false;
    } else {
      return true;
    }
  }
  // 验证身份证号码
  Vue.prototype.idCardNumber = function (value) {
    if (value && (!(/\d{17}[\d|x]|\d{15}/).test(value) || (value.length !== 15 && value.length !== 18))) {
      this.warnMsg('身份证号码不符合规范');
      return false;
    } else {
      return true;
    }
  }


  /*----------     Echart绘图      ----------- */
  // 绘制饼图
  /**
   * 2022-05-25
   * data={
   *     title,  // 标题
   *     subTitle,   // 副标题
   *     seriesData:[{name, value},...]  // 数据
   *     height, // 动态高度
   *     ref,    // 元素钩子
   *     chart,  // 用于保存图表变量
   * } 
   * 示例：src\views\Counter\RegisteredStatistics\index.vue
   */
  Vue.prototype.pieChartDrawing = function (data) {
    console.log("饼图绘图数据：");
    console.log(data);
    // 基于准备好的dom，初始化echarts实例
    if (data.chart != null && data.chart != "" && data.chart != undefined) {
      data.chart.dispose(); //销毁
    }
    data.chart = this.$echarts.init(data.ref, null);
    // 绘制图表
    data.chart.setOption({
      title: {
        text: data.title,
        subtext: data.subTitle,
        left: 'right'
      },
      tooltip: {
        trigger: 'item',
        formatter: data.formatter ? data.formatter : '{b}<br/> {c} ({d}%)'
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            show: true
          }
        },
        left: 'left'
      },
      series: [{
        name: '',
        type: data.type ? data.type : 'pie',
        radius: '55%',
        startAngle: 100,
        center: ['50%', data.height / 2 + 'px'],
        data: data.seriesData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }]
    });
    //防抖
    setTimeout(() => {
      data.chart.getDom().style.height = data.height + 'px';
      data.chart.resize();
    }, 500);
  }

  // 绘制饼图(带右侧菜单)
  /**
   * 2023-03-11
   * data={
   *     title,  // 标题
   *     subTitle,   // 副标题
   *     seriesData:[{name, value},...]  // 数据
   *     height, // 动态高度
   *     ref,    // 元素钩子
   *     chart,  // 用于保存图表变量
   * } 
   * 示例：src\views\Counter\RegisteredStatistics\index.vue
   */
  Vue.prototype.pieChartDrawing2 = function (data) {
    console.log("饼图绘图数据：");
    console.log(data);
    // 基于准备好的dom，初始化echarts实例
    if (data.chart != null && data.chart != "" && data.chart != undefined) {
      data.chart.dispose(); //销毁
    }
    data.chart = this.$echarts.init(data.ref, null);

    let legendData = [];
    data.seriesData.forEach(key => {
      let str = key.name + "(" + key.value + ")";
      legendData.push({
        name: str,
        value: key.value
      })
    });
    console.log(legendData);
    console.log(data.seriesData);
    // 绘制图表
    data.chart.setOption({
      title: {
        text: data.title,
        subtext: data.subTitle,
        left: 'right'
      },
      tooltip: {
        trigger: 'item',
        formatter: data.formatter ? data.formatter : '{b}<br/> {c} ({d}%)'
      },
      legend: {
        orient: 'vertical',
        right: 10,
        y: 'center',
        top: '60px',
        formatter: function (name) {
          let str = name + "(";
          let data1 = data.seriesData;
          for (let i = 0; i < data1.length; i++) {
            if (data1[i].name === name) {
              str += data1[i].value
            }
          }
          return str + ")";
        },
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            show: true
          }
        },
        left: 'left'
      },
      series: [{
        name: '',
        type: data.type ? data.type : 'pie',
        radius: '55%',
        startAngle: 100,
        center: ['40%', data.height / 2 + 'px'],
        data: data.seriesData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }]
    });
    //防抖
    setTimeout(() => {
      data.chart.getDom().style.height = data.height + 'px';
      data.chart.resize();
    }, 500);
  }

  Vue.prototype.consoleTest = function (data) {
    console.log(data);
    return data;
  }

  // 绘制多数值柱状图
  /**
   * this.multiColumnChartDrawing({
   *   title: '销售金额前十',
   *   subTitle: this.dateRangeString(this.filters.dateRange),
   *   labels: nameList,
   *   seriesData: [
   *       { name: "销售金额", type: "bar", data: saleMoneyList },
   *       { name: "销售利润", type: "bar", data: saleProfitList },
   *       { name: "销售数量", type: "bar", data: saleNumberList },
   *   ],
   *   height: 500,
   *   xRange: { start: 0, end: 100 },
   *   yAxisName: "金额/数量",
   *   ref: this.$refs.topSaleChart,
   *   chart: charts['topSaleChart']
   * });
   */
  Vue.prototype.multiColumnChartDrawing = function (data) {
    console.log("多数值柱状图绘图数据：");
    console.log(data);
    // 基于准备好的dom，初始化echarts实例
    if (data.chart != null && data.chart != "" && data.chart != undefined) {
      data.chart.dispose(); //销毁
    }
    var echarts = this.$echarts; // 这行不能简化，绘制图表方法内部也要用
    data.chart = echarts.init(data.ref, null);
    // 绘制图表
    data.chart.setOption({
      title: {
        text: data.title,
        subtext: data.subTitle,
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          label: {
            show: true
          }
        }
      },
      toolbox: {
        show: true,
        feature: {
          mark: {
            show: true
          },
          dataView: {
            show: true,
            readOnly: false
          },
          magicType: {
            show: true,
            type: ['line', 'bar']
          },
          restore: {
            show: true
          },
          saveAsImage: {
            show: true
          }
        }
      },
      calculable: true,
      grid: {
        top: '12%',
        left: '1%',
        right: '10%',
        containLabel: true
      },
      xAxis: [{
        type: 'category',
        data: data.labels
      }],
      yAxis: [{
        type: 'value',
        name: data.yAxisName,
        axisLabel: {
          formatter: function (a) {
            a = +a;
            return isFinite(a) ? echarts.format.addCommas(+a) : '';
          }
        }
      }],
      dataZoom: [{
        show: true,
        start: data.xRange.start,
        end: data.xRange.end
      },
      {
        type: 'inside',
        start: 94,
        end: 100
      },
      {
        show: true,
        yAxisIndex: 0,
        filterMode: 'empty',
        width: 30,
        height: '80%',
        showDataShadow: false,
        left: '93%'
      }
      ],
      series: data.seriesData
    });
    //防抖
    setTimeout(() => {
      data.chart.getDom().style.height = data.height + 'px';
      data.chart.resize();
    }, 500);

  }

  Vue.prototype.multiColumnChartDrawing2 = function (data) {
    console.log("多数值柱状图2绘图数据：");
    console.log(data);
    // 基于准备好的dom，初始化echarts实例
    if (data.chart != null && data.chart != "" && data.chart != undefined) {
      data.chart.dispose(); //销毁
    }
    var echarts = this.$echarts; // 这行不能简化，绘制图表方法内部也要用
    data.chart = echarts.init(data.ref, null);

    let legendData = [];
    data.seriesData.forEach(item => {
      legendData.push(item.name);
    })
    // 绘制图表
    data.chart.setOption({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          label: {
            show: true
          }
        }
      },
      toolbox: {
        show: true,
        feature: {
          mark: {
            show: true
          },
          dataView: {
            show: true,
            readOnly: false
          },
          restore: {
            show: true
          },
          saveAsImage: {
            show: true
          }
        }
      },
      legend: {
        data: legendData
      },
      calculable: true,
      grid: {
        top: '60px',
        left: '20px',
        right: '20px',
        bottom: '20px',
        containLabel: true
      },
      xAxis: [{
        type: 'category',
        data: data.labels,
        axisLabel: {
          show: true,
          textStyle: {
            color: '#000',
            fontSize: '23px'
          }
        },
      }],
      yAxis: [{
        type: 'value',
        name: '金额/数量',
        position: 'left',
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#91CC75'
          }
        },
        axisLabel: {
          formatter: '{value} 元'
        },
        min: function (value) {
          return 0
        }
      }, {
        type: 'value',
        name: '百分比',
        position: 'right',
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#5470C6'
          }
        },
        axisLabel: {
          formatter: '{value} %'
        },
        min: function (value) {
          return 0
        }
      }],
      series: data.seriesData
    });
    //防抖
    setTimeout(() => {
      data.chart.getDom().style.height = data.height + 'px';
      data.chart.resize();
    }, 500);

  }

  // 绘制多数值折线图
  /**
   * this.multiColumnChartDrawing({
   *   title: '销售金额前十',
   *   subTitle: this.dateRangeString(this.filters.dateRange),
   *   labels: nameList,
   *   seriesData: [
   *       { name: "销售金额", type: "bar", data: saleMoneyList },
   *       { name: "销售利润", type: "bar", data: saleProfitList },
   *       { name: "销售数量", type: "bar", data: saleNumberList },
   *   ],
   *   height: 500,
   *   xRange: { start: 0, end: 100 },
   *   yAxisName: "金额/数量",
   *   ref: this.$refs.topSaleChart,
   *   chart: charts['topSaleChart']
   * });
   */
  Vue.prototype.multiLineChartDrawing = function (data) {
    console.log("多数值折线图绘图数据：");
    console.log(data);
    // 基于准备好的dom，初始化echarts实例
    if (data.chart != null && data.chart != "" && data.chart != undefined) {
      data.chart.dispose(); //销毁
    }
    var echarts = this.$echarts; // 这行不能简化，绘制图表方法内部也要用
    data.chart = echarts.init(data.ref, null);
    // 绘制图表
    data.chart.setOption({
      title: {
        text: data.title,
        subtext: data.subTitle,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          label: {
            show: true
          }
        }
      },
      legend: {
        data: data.legend
      },
      toolbox: {
        show: true,
        feature: {
          mark: {
            show: true
          },
          dataView: {
            show: true,
            readOnly: false
          },
          magicType: {
            show: true,
            type: ['line', 'bar']
          },
          restore: {
            show: true
          },
          saveAsImage: {
            show: true
          }
        }
      },
      calculable: true,
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [{
        type: 'category',
        boundaryGap: false,
        data: data.labels
      }],
      yAxis: {
        type: 'value'
      },
      series: data.seriesData
    });
    //防抖
    setTimeout(() => {
      data.chart.getDom().style.height = data.height + 'px';
      data.chart.resize();
    }, 500);

  }


  /*----------     实用方法      ----------- */
  // 计算年龄
  Vue.prototype.ageCalculate = function (strBirthday) {
    var returnAge;
    var dt = new Date(strBirthday);
    var birthYear = dt.getYear();
    var birthMonth = dt.getMonth() + 1;
    var birthDay = dt.getDate();

    var d = new Date();
    var nowYear = d.getYear();
    var nowMonth = d.getMonth() + 1;
    var nowDay = d.getDate();

    if (nowYear == birthYear) {
      returnAge = 0; //同年 则为0岁
    } else {
      var ageDiff = nowYear - birthYear; //年之差
      if (ageDiff > 0) {
        if (nowMonth == birthMonth) {
          var dayDiff = nowDay - birthDay; //日之差
          if (dayDiff < 0) {
            returnAge = ageDiff - 1;
          } else {
            returnAge = ageDiff;
          }
        } else {
          var monthDiff = nowMonth - birthMonth; //月之差
          if (monthDiff < 0) {
            returnAge = ageDiff - 1;
          } else {
            returnAge = ageDiff;
          }
        }
      } else {
        returnAge = -1; //返回-1 表示出生日期输入错误 晚于今天
      }
    }

    return returnAge; //返回周岁年龄
  }

  // 计算百分数
  Vue.prototype.percentageCalculate = function (value, total) {
    if (!total) return 100;
    if (!value) return 0;
    return ((value / total) * 100).toFixed(2);
  }

  // 附加一个空白value（解决新版element自动补充问题）
  Vue.prototype.valueAttach = function (data) {
    if (data && data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        data[i].value = "1";
      }
    }
    return data;
  }


  // 日期快速选择
  // 第一个参数limit -1：限制为今天及之前   0：不限制   1：限制今天及之后
  // 第二个参数range  true：时间段   false：日期
  Vue.prototype.dateFastList = function (limit, range) {
    var shortCut = [{
      text: '今天',
      onClick(picker) {
        picker.$emit('pick', new Date());
      }
    }, {
      text: limit == -1 ? '昨天' : '明天',
      onClick(picker) {
        const date = new Date();
        if (limit == -1) {
          date.setTime(date.getTime() - 3600 * 1000 * 24);
        } else {
          date.setTime(date.getTime() + 3600 * 1000 * 24);
        }
        picker.$emit('pick', date);
      }
    }, {
      text: limit == -1 ? '一周前' : '一周后',
      onClick(picker) {
        const date = new Date();
        if (limit == -1) {
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
        } else {
          date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
        }
        picker.$emit('pick', date);
      }
    }]

    var shortCutRange = [{
      text: '最近三天',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        if (limit == -1) {
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
        } else {
          end.setTime(end.getTime() + 3600 * 1000 * 24 * 3);
        }
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近一周',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        if (limit == -1) {
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        } else {
          end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
        }
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近一个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        if (limit == -1) {
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        } else {
          end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
        }
        picker.$emit('pick', [start, end]);
      }
    }]

    var dateFastList = {
      disabledDate(time) {
        if (limit == -1) {
          return time.getTime() > Date.now();
        } else if (limit == 1) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        }
      },
      shortcuts: range ? shortCutRange : shortCut
    }
    return dateFastList;
  }
}