import axios from '../../axios'
/* 
 * 用户模块
 */

const preUrl = "/auth/user/";

// 用户修改密码
export const updatePassword = (data) => {
    return axios({
        url: preUrl + 'changePassword',
        method: 'post',
        data
    })
}

// 获取菜单树
export const findNavTree = () => {
  return axios({
      url: preUrl + 'findNavTree',
      method: 'get'
  })
}

// 获取用户权限
export const findPermissions = () => {
    return axios({
        url: preUrl + 'findPermissions',
        method: 'get'
    })
}

// 获取用户信息
export const findUserInfo = () => {
    return axios({
        url: preUrl + 'findUserInfo',
        method: 'get'
    })
}