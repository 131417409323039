import axios from '../../../axios'

/* 
 * 挂号类型模块
 */

const preUrl = "/erp/registeredCharge/";

export const findClinicCharge= () => {
    return axios({
        url: preUrl + 'findClinicCharge',
        method: 'get'
    })
}

export const findDetails= (params) => {
    return axios({
        url: preUrl + 'findDetails',
        method: 'get',
        params,
    })
}

export const saveClinicCharge= (data) => {
    return axios({
        url: preUrl + 'saveClinicCharge',
        method: 'post',
        data
    })
}

export const saveDoctorCharge= (data) => {
    return axios({
        url: preUrl + 'saveDoctorCharge',
        method: 'post',
        data
    })
}

export const deleteDoctorCharge= (params) => {
    return axios({
        url: preUrl + 'deleteDoctorCharge',
        method: 'delete',
        params
    })
}