<template>
  <div>
    <span>总计</span>
    <span class="mlef-20" v-for="(item, index) in items" :key="index">{{ item.label }}：{{ sumData[item.prop] }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: {
    sumData: {
      type: Object,
      default: {},
    },
    items: {
      type: Array,
      default: []
    }
  }
}
</script>
