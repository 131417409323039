import axios from '../../../axios'

/* 
 * 症状模块
 */

const preUrl = "/erp/symptom/";

// 获取症状列表
export const findActiveList= () => {
    return axios({
        url: preUrl + 'findActiveList',
        method: 'get'
    })
}

// 保存编辑或新增
export const save= (data) => {
    return axios({
        url: preUrl + 'save',
        method: 'post',
        data
    })
}

// 逻辑删除
export const logicalDelete= (params) => {
    return axios({
        url: preUrl + 'logicalDelete',
        method: 'delete',
        params
    })
}