import axios from '../../../axios'

/* 
 * 处方模块
 */

const preUrl = "/erp/prescriptionOrder/";


export const returnCharge= (data) => {
  return axios({
      url: preUrl + 'returnCharge',
      method: 'post',
      data
  })
}

export const refundOrder= (data) => {
  return axios({
      url: preUrl + 'refundOrder',
      method: 'post',
      data
  })
}

export const refundCharge= (data) => {
  return axios({
      url: preUrl + 'refundCharge',
      method: 'post',
      data
  })
}

export const findPage= (data) => {
  return axios({
      url: preUrl + 'findPage',
      method: 'post',
      data
  })
}

export const excelExport= (data) => {
  return axios({
      url: preUrl + 'excelExport',
      method: 'post',
      data,
      responseType: "blob",
  })
}

export const findDetailsById= (params) => {
  return axios({
      url: preUrl + 'findDetailsById',
      method: 'get',
      params
  })
}

/*
* 获取打印数据
*/
export const findPrintInfo= (params) => {
  return axios({
      url: preUrl + 'findPrintInfo',
      method: 'get',
      params
  })
}

// 收费项目按医生汇总
export const feeCollectByDoctor= (params) => {
  return axios({
      url: preUrl + 'feeCollectByDoctor',
      method: 'get',
      params
  })
}

export const feeCollectByDoctorExport = (params) => {
    return axios({
        url: preUrl + 'feeCollectByDoctorExport',
        method: 'get',
        params,
        responseType: "blob",
    })
}

// 收费项目按科室汇总
export const feeCollectByOffice= (params) => {
  return axios({
      url: preUrl + 'feeCollectByOffice',
      method: 'get',
      params
  })
}

export const feeCollectByOfficeExport = (params) => {
    return axios({
        url: preUrl + 'feeCollectByOfficeExport',
        method: 'get',
        params,
        responseType: "blob",
    })
}

export const doctorMedicineDetails= (data) => {
  return axios({
      url: preUrl + 'doctorMedicineDetails',
      method: 'post',
      data
  })
}

export const doctorMedicineDetailsExport= (data) => {
  return axios({
      url: preUrl + 'doctorMedicineDetailsExport',
      method: 'post',
      data,
      responseType: "blob",
  })
}
