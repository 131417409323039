import axios from '../../axios'

const preUrl = "/admin/log/";

// 分页查找
export const findPage = (data) => {
    return axios({
        url: preUrl + 'findPage',
        method: 'post',
        data
    })
}

// Excel导出
export const excelExport = (data) => {
    return axios({
        url: preUrl + 'excelExport',
        method: 'post',
        data,
        responseType: "blob",
    })
}
