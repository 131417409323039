<template>
  <el-button 
    :size="size"
    :type="type"
    :loading="loading"
    v-if="perms ? hasPerms(perms) : true"
    @click="handleClick"
    :disabled="disabled"
    :class="buttonClass()"
  >
    <span :style="fontStyle()">
      <i v-if="icon" :class="icon"></i>
      {{ label }}
    </span>
  </el-button>
</template>

<script>
export default {
  name: "KtButton",
  props: {
    label: {
      // 按钮显示文本
      type: String,
      default: "Button",
    },
    icon: {
      // 按钮显示图标
      type: String,
      default: "",
    },
    size: {
      // 按钮尺寸
      type: String,
      default: "small",
    },
    fontColor: {
      type: String,
      default: "primary",
    },
    type: {
      // 按钮类型
      type: String,
      default: null,
    },
    loading: {
      // 按钮加载标识
      type: Boolean,
      default: false,
    },
    disabled: {
      // 按钮是否禁用
      type: Boolean,
      default: false,
    },
    perms: {
      // 按钮权限标识，外部使用者传入
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    buttonClass() {
      if (this.type == "text") {
        return "clear-pm";
      }
    },
    fontStyle() {
      if (this.type == "text") {
        return { color: this.colorSelect() };
      }
    },
    colorSelect() {
      switch (this.fontColor) {
        case "primary":
          return "#409EFF";
        case "success":
          return "#67C23A";
        case "info":
          return "#222222";
        case "warning":
          return "#E6A23C";
        case "danger":
          return "#F56C6C";
      }
    },
    handleClick: function () {
      // 按钮操作处理函数
      this.$emit("click", {});
    },
    hasPerms: function (perms) {
      // 根据权限标识和外部指示状态进行权限判断
      return this.hasPermission(perms);
    },
  },
  mounted() {},
};
</script>

<style scoped>
</style>