<template>
  <div class="my-info">
    <slot name="head"></slot>
    <slot></slot>
    <slot name="bottom"></slot>
  </div>
</template>

<script>
export default {
  data(){
    return {

    }
  },
  methods:{

  }
}
</script>

<style lang="less" scoped>
.my-info>:not(:first-child){
  margin-top:20px;
}
</style>