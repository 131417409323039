import axios from '../../../axios'

/* 
 * 回访模块
 */

const preUrl = "/erp/returnVisit/";

// 获取待回访列表
export const findWaitPage = (data) => {
  return axios({
    url: preUrl + 'findWaitPage',
    method: 'post',
    data
  })
}

// 获取待医生待回访列表
export const findWaitPageForDoctor = (data) => {
  return axios({
    url: preUrl + 'findWaitPageForDoctor',
    method: 'post',
    data
  })
}

export const findPatientList = (data) => {
  return axios({
    url: preUrl + 'findPatientList',
    method: 'post',
    data
  })
}

export const findPage = (data) => {
  return axios({
    url: preUrl + 'findPage',
    method: 'post',
    data
  })
}

export const excelExport = (data) => {
  return axios({
      url: preUrl + 'excelExport',
      method: 'post',
      data,
      responseType: "blob",
  })
}

export const statistics = (data) => {
  return axios({
    url: preUrl + 'statistics',
    method: 'post',
    data
  })
}


export const save = (data) => {
  return axios({
    url: preUrl + 'save',
    method: 'post',
    data
  })
}

export const findPatientInfo = (params) => {
  return axios({
    url: preUrl + 'findPatientInfo',
    method: 'get',
    params
  })
}

export const findDetailsById = (params) => {
  return axios({
    url: preUrl + 'findDetailsById',
    method: 'get',
    params
  })
}