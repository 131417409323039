import axios from '../../../axios'

/* 
 * 计量单位模块
 */

const preUrl = "/erp/medicineType/";

export const findActiveList = (params) => {
  return axios({
    url: preUrl + 'findActiveList',
    method: 'get',
    params
  })
}