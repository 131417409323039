import axios from '../../../axios'

/* 
 * 医生模块
 */

const preUrl = "/erp/stockOrder/";

// 分页查找
export const findPage = (data) => {
  return axios({
      url: preUrl + 'findPage',
      method: 'post',
      data
  })
}

// Excel导出
export const excelExport = (data) => {
  return axios({
      url: preUrl + 'excelExport',
      method: 'post',
      data,
      responseType: "blob",
  })
}

export const excelExportDetails = (params) => {
  return axios({
      url: preUrl + 'excelExportDetails',
      method: 'post',
      params,
      responseType: "blob",
  })
}

// 药品出入库
export const medicineStock= (data) => {
  return axios({
      url: preUrl + 'medicineStock',
      method: 'post',
      data
  })
}

// 查询详细
export const findDetailsById = (params) => {
  return axios({
      url: preUrl + 'findDetailsById',
      method: 'get',
      params
  })
}

// 作废订单，并恢复库存
export const logicalDelete= (params) => {
  return axios({
      url: preUrl + 'logicalDelete',
      method: 'delete',
      params
  })
}
