export default {
  state: {
    navTree: [], // 导航菜单树
  },
  getters: {

  },
  mutations: {
    setNavTree(state, navTree) { // 设置导航菜单树
      state.navTree = navTree;
    },
    setNavBadge(state, param) {
      // console.log(param)
      // console.log(state.navTree)
      // state.navTree[0].badge=3;
      let menu = {
        children: state.navTree
      };
      try {
        for (let i = 0, length = param.menuName.length; i < length && menu; i++) {
          menu = menu.children.find(item => item.name == param.menuName[i]);
        }
        if (menu) menu.badge = param.value;
      } catch (e) {
        console.log(e);
      }
    }
  },
  actions: {

  }
}